import React from 'react';
import { ArrowRight } from 'lucide-react';

const CallToAction = () => {
  return (
    <div className="w-full bg-gradient-to-b from-white to-teal-50/20">
      <div className="max-w-6xl mx-auto px-4 py-16">
        <div className="relative">
          {/* Decorative elements */}
          <div className="absolute left-0 top-0 w-24 h-24 bg-teal-100/50 rounded-full -translate-x-1/2 -translate-y-1/2" />
          <div className="absolute right-0 bottom-0 w-32 h-32 bg-teal-100/50 rounded-full translate-x-1/4 translate-y-1/4" />

          {/* Main content */}
          <div className="relative bg-gradient-to-br from-teal-500 to-teal-600 rounded-2xl p-12 shadow-xl overflow-hidden">
            {/* Background decorative circle */}
            <div className="absolute right-0 top-0 w-64 h-64 bg-teal-400/20 rounded-full translate-x-1/3 -translate-y-1/3" />

            <div className="max-w-3xl mx-auto text-center space-y-6 relative z-10">
              <div className="space-y-2">
                <h2 className="text-3xl font-bold text-slate-100 tracking-tight">
                  Build Your Career Globally
                </h2>
                <h3 className="text-5xl font-extrabold text-white tracking-wider animate-pulse">
                  TODAY!!!
                </h3>
              </div>

              <p className="text-xl text-teal-50 tracking-wide">
                Join TalentFirst to unlock opportunities in Europe
              </p>

              <div className="flex justify-center pt-6">
                <button
                  onClick={() =>
                    (window.location.href =
                      'https://forms.gle/Nb2oqFQc4xq2CCjN8')
                  }
                  className="group px-8 py-4 bg-white text-teal-600 rounded-lg font-semibold
                           hover:bg-teal-50 transition-all duration-300
                           shadow-md hover:shadow-xl hover:-translate-y-0.5
                           flex items-center gap-3"
                >
                  Explore Path to Germany
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform duration-300" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
