import { ArrowRight, Globe2, Timer, Users2 } from 'lucide-react';

const TalentHero = ({ applicationLink }) => {
  const quickStats = [
    {
      icon: Globe2,
      value: '30+',
      label: 'Countries',
      sublabel: 'Global Presence',
      rotation: '0deg',
    },
    {
      icon: Timer,
      value: '48h',
      label: 'Response Time',
      sublabel: 'Average',
      rotation: '120deg',
    },
    {
      icon: Users2,
      value: '1000+',
      label: 'Placements',
      sublabel: 'Successfully Matched',
      rotation: '240deg',
    },
  ];

  return (
    <section className="relative overflow-hidden bg-white pt-16 pb-24 lg:pt-20 lg:pb-24">
      <style>
        {`
          @keyframes rotateOnOrbit {
            from {
              transform: rotate(0deg) translateX(var(--orbit-radius, 120px)) rotate(0deg);
            }
            to {
              transform: rotate(360deg) translateX(var(--orbit-radius, 120px)) rotate(-360deg);
            }
          }
          .stat-card {
            --orbit-radius: 120px;
            animation: rotateOnOrbit 30s linear infinite;
            backdrop-filter: blur(8px);
          }
          @media (min-width: 768px) {
            .stat-card {
              --orbit-radius: 180px;
            }
          }
          @media (min-width: 1024px) {
            .stat-card {
              --orbit-radius: 220px;
            }
          }
          .stat-card:nth-child(1) { animation-delay: 0s; }
          .stat-card:nth-child(2) { animation-delay: -10s; }
          .stat-card:nth-child(3) { animation-delay: -20s; }

          .grid-background {
            background-size: 40px 40px;
            background-image: linear-gradient(to right, rgba(0,0,0,0.1) 1px, transparent 1px),
                            linear-gradient(to bottom, rgba(0,0,0,0.1) 1px, transparent 1px);
            mask-image: linear-gradient(to right, black 60%, transparent);
          }
        `}
      </style>

      {/* Enhanced background grid */}
      <div
        className="absolute inset-0 grid-background opacity-30"
        aria-hidden="true"
      />

      <div className="relative container mx-auto px-4">
        <div className="max-w-7xl mx-auto">
          <div className="grid lg:grid-cols-2 gap-12 lg:gap-20 items-center">
            {/* Left content */}
            <div className="max-w-xl lg:max-w-none">
              <div className="inline-flex items-center rounded-full bg-blue-50 px-3 py-1 mb-8 shadow-sm">
                <div className="h-2 w-2 rounded-full bg-blue-600 mr-2" />
                <span className="text-sm font-medium text-blue-900">
                  Global Opportunities
                </span>
              </div>

              <h1 className="text-4xl lg:text-5xl xl:text-6xl font-semibold tracking-tight mb-6">
                Accelerate Your
                <span className="block mt-2 text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600">
                  Global Career
                </span>
              </h1>

              <p className="text-xl text-gray-600 leading-relaxed mb-8 lg:mb-12">
                Join our network of professionals making global impact. We
                provide end-to-end support for your international career
                journey, from application to placement.
              </p>

              <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 sm:gap-6">
                <a
                  href={applicationLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group inline-flex items-center justify-center gap-2 rounded-lg bg-gray-900 px-8 py-3
                         text-white font-medium hover:bg-gray-800 transition-all duration-200
                         hover:shadow-lg hover:shadow-gray-900/25"
                >
                  Start Your Journey
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </a>

                <div className="flex items-center gap-2 text-gray-600">
                  <div className="flex -space-x-2">
                    {[...Array(3)].map((_, i) => (
                      <div
                        key={i}
                        className="w-8 h-8 rounded-full border-2 border-white bg-gray-200"
                      />
                    ))}
                  </div>
                  <span className="text-sm font-medium">
                    Join 1000+ professionals
                  </span>
                </div>
              </div>
            </div>

            {/* Stats grid with geometric animation */}
            <div className="relative h-[400px] md:h-[500px] lg:h-[600px] w-full">
              <div className="absolute inset-0 flex items-center justify-center">
                {quickStats.map((stat, index) => {
                  const Icon = stat.icon;
                  return (
                    <div
                      key={index}
                      className="stat-card absolute w-[140px] md:w-[160px] lg:w-[180px] aspect-square 
                               bg-white/20 rounded-xl transition-all duration-500
                               hover:bg-white/30"
                      style={{
                        transform: `rotate(${stat.rotation}) translateX(var(--orbit-radius)) rotate(-${stat.rotation})`,
                      }}
                    >
                      <div className="flex flex-col items-center justify-center h-full p-6 text-center">
                        <div className="p-3 rounded-lg bg-blue-50/80 mb-3">
                          <Icon className="w-6 h-6 text-blue-600" />
                        </div>
                        <div className="text-2xl md:text-3xl font-bold text-gray-900">
                          {stat.value}
                        </div>
                        <div className="text-sm font-medium text-gray-900 mt-1">
                          {stat.label}
                        </div>
                        <div className="text-sm text-gray-600 mt-1">
                          {stat.sublabel}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TalentHero;
