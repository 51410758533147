import React from 'react';
import EmployerHero from '../components-page-wise/employer-page/EmployerHero';
import ModernEnterprise from '../components-page-wise/employer-page/HowItWorks';
import StatsSection from '../components-page-wise/employer-page/StatsSection';
import ServicesSection from '../components-page-wise/employer-page/WorkforceSolutions';
import EmployerCTA from '../components-page-wise/employer-page/EmployerCTA';

const Employer = () => {
  const googleFormLink = 'https://forms.gle/3A5HELdYoEfLyCRHA';

  return (
    <div className="min-h-screen">
      <EmployerHero googleFormLink={googleFormLink} />
      <ModernEnterprise />
      <StatsSection />
      <ServicesSection />
      <EmployerCTA googleFormLink={googleFormLink} />
    </div>
  );
};

export default Employer;
