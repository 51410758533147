import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logoFooter.png';

const StarryBackground = () => {
  const stars = Array.from({ length: 100 }, (_, i) => ({
    id: i,
    size: Math.random() * 2 + 1,
    top: `${Math.random() * 100}%`,
    left: `${Math.random() * 100}%`,
    animationDelay: `${Math.random() * 3}s`,
  }));

  return (
    <div className="absolute inset-0 overflow-hidden">
      <div className="absolute inset-0 bg-gradient-radial from-gray-900 via-gray-800 to-gray-900" />

      {stars.map((star) => (
        <div
          key={star.id}
          className="absolute rounded-full bg-white animate-twinkle"
          style={{
            width: `${star.size}px`,
            height: `${star.size}px`,
            top: star.top,
            left: star.left,
            animationDelay: star.animationDelay,
            opacity: Math.random() * 0.7 + 0.3,
          }}
        />
      ))}

      <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 via-purple-500/5 to-transparent animate-pulse" />
    </div>
  );
};

const Footer = () => {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const fetchUrl = `${apiUrl}/api/emaillist`;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    setShouldSubmit(true);
  };

  useEffect(() => {
    const subscribeEmail = async () => {
      if (shouldSubmit) {
        try {
          const response = await fetch(fetchUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
          });

          if (response.ok) {
            setIsSubscribed(true);
            setEmail('');
            setErrorMessage('');
          } else {
            setErrorMessage('Failed to subscribe. Please try again later.');
          }
        } catch (error) {
          setErrorMessage('There was a network error. Please try again later.');
          console.error('Network error:', error);
        }
        setShouldSubmit(false);
      }
    };

    subscribeEmail();
  }, [shouldSubmit, fetchUrl, email]);

  return (
    <footer className="relative bg-gray-900 text-white py-16">
      <StarryBackground />

      {/* Subtle overlay for text readability */}
      <div className="absolute inset-0 bg-gray-900/30" />

      <div className="relative z-10 container mx-auto px-4">
        {/* Newsletter Section */}
        <div className="p-16 mb-24 pt-6 border-gray-700 border-b">
          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/2 mb-4 md:mb-0">
              <h2 className="text-xl relative sm:text-left text-center font-semibold mb-2 mr-4">
                Join our email subscription now to get updates on new
                opportunities and notifications.
              </h2>
            </div>
            <div className="md:w-1/2">
              {isSubscribed ? (
                <div className="text-center">
                  <p className="text-white text-lg font-semibold mb-4">
                    Thank you for subscribing to our email list!
                  </p>
                  <button
                    className="bg-custome-gray text-white py-2 px-4 rounded-lg border-none hover:bg-gray-500"
                    onClick={() => setIsSubscribed(false)}
                  >
                    Subscribe Another Email
                  </button>
                </div>
              ) : (
                <form className="flex" onSubmit={handleEmailSubmit}>
                  <input
                    name="news-letter"
                    className="form-control nl-0 justify-center text-black flex-grow py-2 px-1 sm:px-4 rounded-l-lg border-none"
                    placeholder="Enter Your Email"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                  <button
                    type="submit"
                    className="bg-custome-gray text-white py-2 px-2 rounded-r-lg border-none hover:bg-gray-500"
                  >
                    Subscribe Now
                  </button>
                </form>
              )}
              {errorMessage && (
                <p className="mt-2 text-red-500 text-sm">{errorMessage}</p>
              )}
            </div>
          </div>
        </div>

        {/* Footer Sections */}
        <div className="flex flex-col lg:flex-row lg:items-start lg:space-x-52 xl:space-x-72 2xl:space-x-96">
          {/* Left Side: Logo and Company Info */}
          <div className="w-full md:ml-20 lg:ml-28 lg:w-1/3 mb-8 lg:mb-0">
            <img
              src={logo}
              alt="TalentFirst Logo"
              className="mb-6 w-32 mx-auto lg:mx-0"
            />

            <p className="text-sm leading-relaxed text-justify mt-4 mb-10">
              TalentFirst connects top talent with leading companies globally.
              We bridge the gap between opportunity and talent, empowering both
              job seekers and employers with innovative solutions.
            </p>

            <h2 className="text-xl font-semibold mb-2 flex justify-center lg:justify-start text-justify lg:text-left">
              Chainlink Technology Pvt Ltd
            </h2>
            <div className="text-sm flex flex-col justify-between lg:justify-start leading-relaxed">
              <span className="flex justify-center items-center lg:items-start lg:justify-start flex-col">
                29/A, 27th Main Rd, Sector 5,
                <br />
                11st Sector, HSR Layout,
                <br />
                Bengaluru, Karnataka 560102
                <br />
                <span className="flex justify-center lg:justify-start text-justified lg:text-left mt-1">
                  Phone: <a href="tel:+91-7975563057">+91-7975563057</a>
                  <br />
                </span>
              </span>
              <span className="flex justify-center lg:justify-start text-justified lg:text-left">
                Email:{' '}
                <a
                  href="mailto:connect@talentfirst.global"
                  className="hover:underline text-blue-400"
                >
                  {' '}
                  connect@talentfirst.global
                </a>
              </span>
            </div>

            {/* Social Media Links */}
            <div className="mt-6 flex justify-center lg:justify-start space-x-4">
              <a
                href="https://www.linkedin.com/company/talentfirst-global"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline text-white"
              >
                LinkedIn
              </a>
              <a
                href="https://www.instagram.com/talentfirst_recruiting/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline text-white"
              >
                Instagram
              </a>
              <a
                href="https://www.facebook.com/talentfirstrecruiting"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline text-white"
              >
                Facebook
              </a>
            </div>
          </div>

          {/* Right Side: Navigation Links */}
          <div className="w-full lg:w-2/3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center lg:text-left">
            {/* Site Links */}
            <div>
              <h4 className="text-lg font-semibold mb-4">Site Links</h4>
              <ul className="space-y-3">
                <li>
                  <Link to="/" className="hover:underline">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="hover:underline">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/employer" className="hover:underline">
                    For Employers
                  </Link>
                </li>
                <li>
                  <Link to="/talent" className="hover:underline">
                    For Talent
                  </Link>
                </li>
              </ul>
            </div>

            {/* Legal Section */}
            <div>
              <h4 className="text-lg font-semibold mb-4">Legal</h4>
              <ul className="space-y-3">
                <li>
                  <Link to="/privacypolicy" className="hover:underline">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/refund_policy" className="hover:underline">
                    Refund Policy
                  </Link>
                </li>
                <li>
                  <Link to="/terms&conditions" className="hover:underline">
                    Terms & Conditions
                  </Link>
                </li>
              </ul>
            </div>

            {/* Contact Section */}
            <div>
              <h4 className="text-lg font-semibold mb-4 mt-2">Contact</h4>
              <ul className="space-y-3">
                <li>
                  <Link to="/contact" className="hover:underline">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-8 border-t border-gray-700 pt-6 text-center">
          <p className="text-sm">
            &copy; 2024 TalentFirst. All rights reserved. Chainlink Technology
            Pvt. Ltd.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
