// src/state/headerState.js
import { atom } from 'recoil';

export const stickyHeaderState = atom({
  key: 'stickyHeaderState', 
  default: true, 
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        console.log('Header state changed to:', newValue);
      });
    },
  ],
});
