import React from 'react';
import Hero from '../components/hero';
import Services from '../components/Services';
import ProgramHighlight from '../components/ProgramHighlight';
import FutureIndustryList from '../components/FutureIndustryList';
import CallToAction from '../components/CallToAction';

const Home = () => {
  return (
    <div className="min-h-screen w-full overflow-x-hidden">
      <div className="flex flex-col space-y-8 mobile:space-y-12 sm:space-y-16">
        <Hero />
        <ProgramHighlight />
        <Services />
        <FutureIndustryList />
        <CallToAction />
      </div>
    </div>
  );
};

export default Home;
