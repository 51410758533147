// src/state/headerState.js
import { atom } from 'recoil';

// Atom to manage the sticky header visibility
export const stickyFooterState = atom({
  key: 'stickyFooterState', // unique ID
  default: true, 
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        console.log('Footer state changed to:', newValue);
      });
    },
  ]
});
