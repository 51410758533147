import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/logo-highdef.png';
import { useSetRecoilState } from 'recoil';
import { showBannerState } from '../recoil/showBanner';

const Header = () => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const setShowBannerState = useSetRecoilState(showBannerState);
  const [activePath, setActivePath] = useState('/');

  useEffect(() => {
    if (location.pathname === '/germany') {
      setShowBannerState(false);
    } else {
      setShowBannerState(true);
    }
    setActivePath(location.pathname);
  }, [location.pathname, setShowBannerState]);

  const navItems = [
    { path: '/', label: 'Home' },
    { path: '/talent', label: 'Talent' },
    { path: '/employer', label: 'Employer' },
    { path: '/contact', label: 'Contact' },
  ];

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const NavLink = ({ to, children }) => {
    const isActive = activePath === to;
    return (
      <Link
        to={to}
        className={`text-custom-blue font-medium transition-colors ${
          isActive
            ? 'text-teal-500 relative after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-teal-500'
            : 'hover:text-teal-500'
        }`}
      >
        {children}
      </Link>
    );
  };

  const MobileNavLink = ({ to, children }) => {
    const isActive = activePath === to;
    return (
      <Link
        to={to}
        onClick={toggleMenu}
        className={`text-xl font-medium transition-colors ${
          isActive ? 'text-teal-500' : 'text-custom-blue hover:text-teal-500'
        }`}
      >
        {children}
      </Link>
    );
  };

  return (
    <>
      <header className="fixed top-0 left-0 w-full z-40 px-4">
        <div className="container mx-auto flex justify-between items-center py-4">
          {/* Logo */}
          <Link to="/" className="relative z-50">
            <img src={logo} alt="TalentFirst Logo" className="h-10 w-auto" />
          </Link>

          {/* Centered Navigation */}
          <nav className="hidden md:block">
            <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-white/80 backdrop-blur-lg rounded-full px-8 py-4 shadow-lg">
              <div className="flex space-x-8">
                {navItems.map((item) => (
                  <NavLink key={item.path} to={item.path}>
                    {item.label}
                  </NavLink>
                ))}
              </div>
            </div>
          </nav>

          {/* Auth Button */}

          {/* Mobile Menu Button */}
          <button
            onClick={toggleMenu}
            className="md:hidden relative z-50 p-2 rounded-lg hover:bg-gray-100"
          >
            <svg
              className="w-6 h-6 text-custom-blue"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={menuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}
              />
            </svg>
          </button>
        </div>

        {/* Mobile Navigation */}
        {menuOpen && (
          <div className="md:hidden fixed inset-0 bg-white/95 backdrop-blur-sm z-40">
            <nav className="h-full flex flex-col items-center justify-center">
              <ul className="flex flex-col items-center space-y-8 mb-8">
                {navItems.map((item) => (
                  <li key={item.path}>
                    <MobileNavLink to={item.path}>{item.label}</MobileNavLink>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
