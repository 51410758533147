import { RecoilRoot } from 'recoil';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Talent from './pages/Talent';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Contact from './pages/Contact';
import Employer from './pages/Employer';
import TermsConditions from './pages/TermsConditions';
import NotFound from './pages/NotFound';
import PublicLayout from './Layouts/PublicLayout';
import RefundPolicy from './pages/RefundPolicy';
import useScrollToTop from './hooks/scrollToTop';
import Unauthorized from './pages/Unauthorized';
import usePageMounting from './hooks/usePageMounting';

function App() {
  useScrollToTop();
  usePageMounting();

  return (
    <div className="m-0 p-0">
      <RecoilRoot>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<PublicLayout />}>
            <Route index element={<Home />} />
            <Route path="/employer" element={<Employer />} />
            <Route path="/talent" element={<Talent />} />
            <Route path="/terms&conditions" element={<TermsConditions />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/refund_policy" element={<RefundPolicy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </RecoilRoot>
    </div>
  );
}

export default App;
