import React, { useEffect } from 'react';
import Header from '../components/Header';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { stickyHeaderState } from '../recoil/headerState';
import { stickyFooterState } from '../recoil/footerState';
import StickyBanner from '../components/StickyBanner';
import Footer from '../components/Footer';

const PublicLayout = () => {
  const [showHeader, setHeaderState] = useRecoilState(stickyHeaderState);
  const [showFooter, setFooterState] = useRecoilState(stickyFooterState);
  const location = useLocation();
  const navigate = useNavigate();

  // Add history listener for navigation changes
  useEffect(() => {
    console.log('Current pathname:', location.pathname);

    const updateLayoutState = () => {
      if (location.pathname === '/germany') {
        console.log('Setting states to false for Germany page');
        setHeaderState(false);
        setFooterState(false);
      } else {
        console.log('Setting states to true for other pages');
        setHeaderState(true);
        setFooterState(true);
      }
    };

    updateLayoutState();
    window.addEventListener('popstate', updateLayoutState);

    return () => {
      window.removeEventListener('popstate', updateLayoutState);
    };
  }, [location.pathname, setHeaderState, setFooterState]);

  useEffect(() => {
    const unlisten = navigate((location) => {
      console.log('Navigation occurred to:', location.pathname);
      if (location.pathname === '/germany') {
        setHeaderState(false);
        setFooterState(false);
      } else {
        setHeaderState(true);
        setFooterState(true);
      }
    });

    return unlisten;
  }, [navigate, setHeaderState, setFooterState]);

  const showBanner = showHeader && location.pathname !== '/germany';

  return (
    <div
      className={`transition-all duration-300 ${
        showHeader ? (showBanner ? 'pt-32' : 'pt-24') : ''
      }`}
    >
      {showHeader && (
        <>
          <Header />
          {showBanner && (
            <div className="fixed top-20 left-0 w-full z-30 px-4">
              <StickyBanner />
            </div>
          )}
        </>
      )}

      <main className="min-h-screen">
        <Outlet />
      </main>

      {showFooter && <Footer />}
    </div>
  );
};

export default PublicLayout;
