// MarketInsights.js
import React, { useState } from 'react';
import SelectionView from './SelectionView';
import ResultsView from './ResultsView';
import marketData from '../../../data/marketData';
import useIsMobile from '../../../hooks/useIsMobile';

const MarketInsights = () => {
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [selectedSubIndustry, setSelectedSubIndustry] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const isMobile = useIsMobile();

  const handleSelectionChange = (type, value) => {
    switch (type) {
      case 'industry':
        setSelectedIndustry(selectedIndustry === value ? '' : value);
        setSelectedSubIndustry('');
        setSelectedRole('');
        break;
      case 'subIndustry':
        setSelectedSubIndustry(selectedSubIndustry === value ? '' : value);
        setSelectedRole('');
        break;
      case 'role':
        setSelectedRole(selectedRole === value ? '' : value);
        break;
      default:
        break;
    }
  };

  const getAvailableCountries = () => {
    if (!selectedIndustry || !selectedSubIndustry || !selectedRole) return [];
    return Object.entries(
      marketData[selectedIndustry]?.subIndustries[selectedSubIndustry]?.roles[
        selectedRole
      ] || {}
    );
  };

  return (
    <div className="max-w-7xl mx-auto px-4">
      <div className="flex flex-col p-1">
        <div className="py-6 md:py-8 flex flex-col items-center md:items-start justify-center border-b border-gray-200">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-2 tracking-tight text-center md:text-left">
            Global Industry Opportunities
          </h2>
          <p className="text-base md:text-lg text-gray-600 text-center md:text-left">
            Explore opportunities across different industries in Europe by
            Selecting Industry, Sector and Role
          </p>
        </div>

        <div className="flex-1 flex flex-col md:flex-row gap-6 py-6">
          <SelectionView
            marketData={marketData}
            selectedIndustry={selectedIndustry}
            selectedSubIndustry={selectedSubIndustry}
            selectedRole={selectedRole}
            onSelectionChange={handleSelectionChange}
          />
          {/* {(!isMobile || (isMobile && selectedRole)) && (
            <ResultsView countries={getAvailableCountries()} />
          )} */}
          {/* {!isMobile ? (
            <ResultsView countries={getAvailableCountries()} />
          ) : isMobile && selectedRole ? (
            <ResultsView countries={getAvailableCountries()} />
          ) : (
            ' '
          )} */}
          {isMobile ? (
            selectedRole ? (
              <ResultsView countries={getAvailableCountries()} />
            ) : null
          ) : (
            <ResultsView countries={getAvailableCountries()} />
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketInsights;
