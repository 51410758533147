// import React, { useState } from 'react';
// import {
//   Building,
//   UserCheck,
//   Shield,
//   GraduationCap,
//   Globe,
// } from 'lucide-react';

// const Services = () => {
//   const [activeTab, setActiveTab] = useState(0);

//   const services = [
//     {
//       Icon: Building,
//       title: 'Enterprise Staffing',
//       description:
//         'Strategic talent acquisition for scaling organizations. Customized recruitment solutions tailored to your business needs.',
//       points: [
//         'Dedicated talent pools',
//         'Skill-specific recruitment',
//         'Customized hiring workflows',
//         'Compliance management',
//       ],
//     },
//     {
//       Icon: UserCheck,
//       title: 'Workforce Solutions',
//       description:
//         'End-to-end employee lifecycle management and support. Optimizing workforce performance through integrated services.',
//       points: [
//         'Contract staffing',
//         'Payroll management',
//         'Performance tracking',
//         'Resource optimization',
//       ],
//     },
//     {
//       Icon: Shield,
//       title: 'Quality Assurance',
//       description:
//         'Comprehensive verification and validation processes. Ensuring highest standards of talent quality and compliance.',
//       points: [
//         'Background verification',
//         'Skill assessment',
//         'Reference checks',
//         'Compliance documentation',
//       ],
//     },
//     {
//       Icon: GraduationCap,
//       title: 'Talent Development',
//       description:
//         'Custom training programs for workforce enhancement. Bridging skill gaps with targeted, adaptive learning solutions.',
//       points: [
//         'Skill gap analysis',
//         'Training implementation',
//         'Progress monitoring',
//         'Performance metrics',
//       ],
//     },
//     {
//       Icon: Globe,
//       title: 'Global Mobility',
//       description:
//         'International workforce management and relocation services. Seamless global talent movement with comprehensive support.',
//       points: [
//         'Visa processing',
//         'Relocation support',
//         'Cultural training',
//         'Global compliance',
//       ],
//     },
//   ];

//   const ActiveIcon = services[activeTab].Icon;

//   return (
//     <section className="py-20 bg-gray-50">
//       <div className="max-w-7xl mx-auto px-4">
//         <div className="text-center mb-16">
//           <span className="text-blue-600 font-semibold text-sm uppercase tracking-wider">
//             For Enterprises
//           </span>
//           <h2 className="mt-2 text-3xl font-bold text-gray-900">
//             Comprehensive Business Solutions
//           </h2>
//           <p className="mt-4 text-gray-600 max-w-2xl mx-auto">
//             Streamline your workforce management with our end-to-end services
//             designed for modern enterprises
//           </p>
//         </div>

//         <div className="flex flex-col lg:flex-row gap-8 h-full">
//           {/* Service Navigation */}
//           <div className="lg:w-1/4 h-full">
//             <div className="sticky top-24 space-y-2 h-full">
//               {services.map((service, index) => {
//                 const ServiceIcon = service.Icon;
//                 return (
//                   <button
//                     key={index}
//                     onClick={() => setActiveTab(index)}
//                     className={`w-full flex items-center gap-3 p-4 rounded-lg text-left transition-all duration-300 ${
//                       activeTab === index
//                         ? 'bg-blue-600 text-white shadow-lg'
//                         : 'bg-white text-gray-700 hover:bg-gray-100'
//                     }`}
//                   >
//                     <ServiceIcon className="w-5 h-5" />
//                     <span className="font-medium">{service.title}</span>
//                   </button>
//                 );
//               })}
//             </div>
//           </div>

//           {/* Service Details */}
//           <div className="lg:w-3/4">
//             <div className="bg-white rounded-xl p-8 shadow-lg h-full">
//               <div className="flex items-center gap-4 mb-6">
//                 <div className="bg-blue-500/10 p-3 rounded-lg">
//                   <ActiveIcon className="w-6 h-6 text-blue-600" />
//                 </div>
//                 <h3 className="text-2xl font-bold text-gray-900">
//                   {services[activeTab].title}
//                 </h3>
//               </div>

//               <p className="text-gray-600 text-lg mb-6 whitespace-pre-line">
//                 {services[activeTab].description}
//               </p>

//               <div className="grid sm:grid-cols-2 gap-4">
//                 {services[activeTab].points.map((point, index) => (
//                   <div
//                     key={index}
//                     className="flex items-center gap-2 p-4 bg-gray-50 rounded-lg"
//                   >
//                     <div className="w-1 h-1 rounded-full bg-blue-500" />
//                     <span className="text-gray-700">{point}</span>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Services;

// import React, { useState } from 'react';
// import {
//   Building,
//   UserCheck,
//   Shield,
//   GraduationCap,
//   Globe,
//   ChevronLeft,
//   ChevronRight,
// } from 'lucide-react';

// const Services = () => {
//   const [activeTab, setActiveTab] = useState(0);

//   const services = [
//     {
//       Icon: Building,
//       title: 'Enterprise Staffing',
//       description:
//         'Strategic talent acquisition for scaling organizations. Customized recruitment solutions tailored to your business needs.',
//       points: [
//         'Dedicated talent pools',
//         'Skill-specific recruitment',
//         'Customized hiring workflows',
//         'Compliance management',
//       ],
//     },
//     {
//       Icon: UserCheck,
//       title: 'Workforce Solutions',
//       description:
//         'End-to-end employee lifecycle management and support. Optimizing workforce performance through integrated services.',
//       points: [
//         'Contract staffing',
//         'Payroll management',
//         'Performance tracking',
//         'Resource optimization',
//       ],
//     },
//     {
//       Icon: Shield,
//       title: 'Quality Assurance',
//       description:
//         'Comprehensive verification and validation processes. Ensuring highest standards of talent quality and compliance.',
//       points: [
//         'Background verification',
//         'Skill assessment',
//         'Reference checks',
//         'Compliance documentation',
//       ],
//     },
//     {
//       Icon: GraduationCap,
//       title: 'Talent Development',
//       description:
//         'Custom training programs for workforce enhancement. Bridging skill gaps with targeted, adaptive learning solutions.',
//       points: [
//         'Skill gap analysis',
//         'Training implementation',
//         'Progress monitoring',
//         'Performance metrics',
//       ],
//     },
//     {
//       Icon: Globe,
//       title: 'Global Mobility',
//       description:
//         'International workforce management and relocation services. Seamless global talent movement with comprehensive support.',
//       points: [
//         'Visa processing',
//         'Relocation support',
//         'Cultural training',
//         'Global compliance',
//       ],
//     },
//   ];

//   const ActiveIcon = services[activeTab].Icon;

//   // Touch handling
//   const [touchStart, setTouchStart] = useState(null);
//   const [touchEnd, setTouchEnd] = useState(null);

//   const nextSlide = () => {
//     if (activeTab < services.length - 1) {
//       setActiveTab(activeTab + 1);
//     }
//   };

//   const prevSlide = () => {
//     if (activeTab > 0) {
//       setActiveTab(activeTab - 1);
//     }
//   };

//   const handleTouchStart = (e) => {
//     setTouchStart(e.touches[0].clientX);
//   };

//   const handleTouchMove = (e) => {
//     setTouchEnd(e.touches[0].clientX);
//   };

//   const handleTouchEnd = () => {
//     if (!touchStart || !touchEnd) return;
//     const distance = touchStart - touchEnd;
//     const isLeftSwipe = distance > 50;
//     const isRightSwipe = distance < -50;

//     if (isLeftSwipe && activeTab < services.length - 1) {
//       nextSlide();
//     }
//     if (isRightSwipe && activeTab > 0) {
//       prevSlide();
//     }

//     setTouchStart(null);
//     setTouchEnd(null);
//   };

//   return (
//     <section className="py-20 bg-gray-50">
//       <div className="max-w-7xl mx-auto px-4 sm:px-8 md:px-12 lg:px-8">
//         <div className="text-center mb-16">
//           <span className="text-blue-600 font-semibold text-sm uppercase tracking-wider">
//             For Enterprises
//           </span>
//           <h2 className="mt-2 text-3xl font-bold text-gray-900">
//             Comprehensive Business Solutions
//           </h2>
//           <p className="mt-4 text-gray-600 max-w-2xl mx-auto sm:px-6">
//             Streamline your workforce management with our end-to-end services
//             designed for modern enterprises
//           </p>
//         </div>

//         {/* Desktop View */}
//         <div className="hidden lg:flex lg:flex-row gap-8 h-full">
//           <div className="lg:w-1/4 h-full">
//             <div className="sticky top-24 space-y-2 h-full">
//               {services.map((service, index) => {
//                 const ServiceIcon = service.Icon;
//                 return (
//                   <button
//                     key={index}
//                     onClick={() => setActiveTab(index)}
//                     className={`w-full flex items-center gap-3 p-4 rounded-lg text-left transition-all duration-300 ${
//                       activeTab === index
//                         ? 'bg-blue-600 text-white shadow-lg'
//                         : 'bg-white text-gray-700 hover:bg-gray-100'
//                     }`}
//                   >
//                     <ServiceIcon className="w-5 h-5" />
//                     <span className="font-medium">{service.title}</span>
//                   </button>
//                 );
//               })}
//             </div>
//           </div>

//           <div className="lg:w-3/4">
//             <div className="bg-white rounded-xl p-8 shadow-lg h-full">
//               <div className="flex items-center gap-4 mb-6">
//                 <div className="bg-blue-500/10 p-3 rounded-lg">
//                   <ActiveIcon className="w-6 h-6 text-blue-600" />
//                 </div>
//                 <h3 className="text-2xl font-bold text-gray-900">
//                   {services[activeTab].title}
//                 </h3>
//               </div>

//               <p className="text-gray-600 text-lg mb-6 whitespace-pre-line">
//                 {services[activeTab].description}
//               </p>

//               <div className="grid sm:grid-cols-2 gap-4">
//                 {services[activeTab].points.map((point, index) => (
//                   <div
//                     key={index}
//                     className="flex items-center gap-2 p-4 bg-gray-50 rounded-lg"
//                   >
//                     <div className="w-1 h-1 rounded-full bg-blue-500" />
//                     <span className="text-gray-700">{point}</span>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Mobile View */}
//         <div className="lg:hidden">
//           {/* Service title and navigation dots */}
//           <div className="flex flex-col items-center mb-2">
//             <div className="flex gap-2 mb-4">
//               {services.map((_, index) => (
//                 <div
//                   key={index}
//                   className={`w-2 h-2 rounded-full transition-all duration-300 ${
//                     activeTab === index ? 'w-4 bg-blue-600' : 'bg-gray-300'
//                   }`}
//                 />
//               ))}
//             </div>
//           </div>

//           {/* Swipeable content */}
//           <div
//             className="relative w-full p-3 mx-auto sm:max-w-xl md:max-w-2xl"
//             onTouchStart={handleTouchStart}
//             onTouchMove={handleTouchMove}
//             onTouchEnd={handleTouchEnd}
//           >
//             <div
//               className="flex transition-transform duration-300 ease-in-out"
//               style={{
//                 transform: `translateX(-${activeTab * 100}%)`,
//                 width: `${services.length * 100}%`,
//               }}
//             >
//               {services.map((service, index) => (
//                 <div key={index} className="w-full flex-shrink-0 px-1 sm:px-2">
//                   <div className="bg-white rounded-xl p-6 sm:p-8 shadow-lg border">
//                     <div className="flex items-center gap-4 mb-6">
//                       <div className="bg-blue-500/10 p-3 rounded-lg">
//                         <service.Icon className="w-6 h-6 text-blue-600" />
//                       </div>
//                       <h3 className="text-xl sm:text-2xl font-bold text-gray-900">
//                         {service.title}
//                       </h3>
//                     </div>
//                     <div className="w-full overflow-hidden mb-6">
//                       <p className="text-gray-600 text-base sm:text-lg break-words hyphens-auto">
//                         {service.description}
//                       </p>
//                     </div>
//                     <div className="grid gap-4">
//                       {service.points.map((point, idx) => (
//                         <div
//                           key={idx}
//                           className="flex items-center gap-2 p-4 bg-gray-50 rounded-lg"
//                         >
//                           <div className="w-1 h-1 rounded-full bg-blue-500" />
//                           <span className="text-gray-700 text-sm sm:text-base">
//                             {point}
//                           </span>
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//             {/* Navigation arrows */}
//             {activeTab > 0 && (
//               <button
//                 onClick={prevSlide}
//                 className="absolute left-2 sm:left-4 top-1/2 -translate-y-1/2 p-2 bg-white rounded-full shadow-lg z-10"
//               >
//                 <ChevronLeft className="w-6 h-6 text-gray-600" />
//               </button>
//             )}
//             {activeTab < services.length - 1 && (
//               <button
//                 onClick={nextSlide}
//                 className="absolute right-2 sm:right-4 top-1/2 -translate-y-1/2 p-2 bg-white rounded-full shadow-lg z-10"
//               >
//                 <ChevronRight className="w-6 h-6 text-gray-600" />
//               </button>
//             )}
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Services;

// import React, { useState } from 'react';
// import {
//   Building,
//   UserCheck,
//   Shield,
//   GraduationCap,
//   Globe,
//   ChevronLeft,
//   ChevronRight,
// } from 'lucide-react';

// const ServiceCard = ({ service }) => {
//   return (
//     <div className="bg-white p-6 mobile:p-8 rounded-2xl shadow-lg h-full">
//       <div className="flex items-start gap-4 mb-6">
//         <div className="bg-blue-500/10 p-3 rounded-xl shrink-0">
//           <service.Icon className="w-5 h-5 mobile:w-6 mobile:h-6 text-blue-600" />
//         </div>
//         <h3 className="text-lg mobile:text-xl font-bold text-gray-900 leading-tight">
//           {service.title}
//         </h3>
//       </div>

//       <p className="text-gray-600 text-sm mobile:text-base mb-6">
//         {service.description}
//       </p>

//       <div className="grid gap-4">
//         {service.points.map((point, idx) => (
//           <div
//             key={idx}
//             className="flex items-center gap-3 p-3 mobile:p-4 bg-gray-50 rounded-xl"
//           >
//             <div className="w-1.5 h-1.5 bg-blue-500 rounded-full shrink-0" />
//             <span className="text-gray-700 text-xs mobile:text-sm">
//               {point}
//             </span>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// const Services = () => {
//   const [activeTab, setActiveTab] = useState(0);
//   const [touchStart, setTouchStart] = useState(null);
//   const [touchEnd, setTouchEnd] = useState(null);

//   const services = [
//     {
//       Icon: Building,
//       title: 'Enterprise Staffing',
//       description:
//         'Strategic talent acquisition for scaling organizations. Customized recruitment solutions tailored to your business needs.',
//       points: [
//         'Dedicated talent pools',
//         'Skill-specific recruitment',
//         'Customized hiring workflows',
//         'Compliance management',
//       ],
//     },
//     {
//       Icon: UserCheck,
//       title: 'Workforce Solutions',
//       description:
//         'End-to-end employee lifecycle management and support. Optimizing workforce performance through integrated services.',
//       points: [
//         'Contract staffing',
//         'Payroll management',
//         'Performance tracking',
//         'Resource optimization',
//       ],
//     },
//     {
//       Icon: Shield,
//       title: 'Quality Assurance',
//       description:
//         'Comprehensive verification and validation processes. Ensuring highest standards of talent quality and compliance.',
//       points: [
//         'Background verification',
//         'Skill assessment',
//         'Reference checks',
//         'Compliance documentation',
//       ],
//     },
//     {
//       Icon: GraduationCap,
//       title: 'Talent Development',
//       description:
//         'Custom training programs for workforce enhancement. Bridging skill gaps with targeted, adaptive learning solutions.',
//       points: [
//         'Skill gap analysis',
//         'Training implementation',
//         'Progress monitoring',
//         'Performance metrics',
//       ],
//     },
//     {
//       Icon: Globe,
//       title: 'Global Mobility',
//       description:
//         'International workforce management and relocation services. Seamless global talent movement with comprehensive support.',
//       points: [
//         'Visa processing',
//         'Relocation support',
//         'Cultural training',
//         'Global compliance',
//       ],
//     },
//   ];

//   const handleTouchStart = (e) => {
//     setTouchStart(e.touches[0].clientX);
//   };

//   const handleTouchMove = (e) => {
//     setTouchEnd(e.touches[0].clientX);
//   };

//   const handleTouchEnd = () => {
//     if (!touchStart || !touchEnd) return;
//     const distance = touchStart - touchEnd;
//     const isLeftSwipe = distance > 50;
//     const isRightSwipe = distance < -50;

//     if (isLeftSwipe && activeTab < services.length - 1) {
//       setActiveTab(activeTab + 1);
//     }
//     if (isRightSwipe && activeTab > 0) {
//       setActiveTab(activeTab - 1);
//     }

//     setTouchStart(null);
//     setTouchEnd(null);
//   };

//   return (
//     <section className="py-8 mobile:py-12 sm:py-16 lg:py-20 bg-gray-50">
//       <div className="max-w-7xl mx-auto px-3 mobile:px-4 sm:px-6 lg:px-8">
//         {/* Header Section */}
//         <div className="text-center mb-8 mobile:mb-10 lg:mb-16">
//           <span className="text-blue-600 font-semibold text-xs mobile:text-sm uppercase tracking-wider">
//             For Enterprises
//           </span>
//           <h2 className="mt-2 text-2xl mobile:text-3xl font-bold text-gray-900">
//             Comprehensive Business Solutions
//           </h2>
//           <p className="mt-3 mobile:mt-4 text-sm mobile:text-base text-gray-600 max-w-2xl mx-auto">
//             Streamline your workforce management with our end-to-end services
//             designed for modern enterprises
//           </p>
//         </div>

//         {/* Desktop View */}
//         <div className="hidden lg:flex lg:flex-row gap-8">
//           <div className="lg:w-1/4">
//             <div className="sticky top-24 space-y-2">
//               {services.map((service, index) => (
//                 <button
//                   key={index}
//                   onClick={() => setActiveTab(index)}
//                   className={`w-full flex items-center gap-3 p-4 rounded-lg text-left transition-all duration-300 ${
//                     activeTab === index
//                       ? 'bg-blue-600 text-white shadow-lg'
//                       : 'bg-white text-gray-700 hover:bg-gray-100'
//                   }`}
//                 >
//                   <service.Icon className="w-5 h-5" />
//                   <span className="font-medium">{service.title}</span>
//                 </button>
//               ))}
//             </div>
//           </div>

//           <div className="lg:w-3/4">
//             <ServiceCard service={services[activeTab]} />
//           </div>
//         </div>

//         {/* Mobile View */}
//         <div className="lg:hidden w-full">
//           <div className="flex justify-center gap-2 mb-6">
//             {services.map((_, index) => (
//               <div
//                 key={index}
//                 className={`h-2 rounded-full transition-all duration-300 ${
//                   activeTab === index ? 'w-4 bg-blue-600' : 'w-2 bg-gray-300'
//                 }`}
//               />
//             ))}
//           </div>

//           <div
//             className="relative w-full overflow-hidden"
//             onTouchStart={handleTouchStart}
//             onTouchMove={handleTouchMove}
//             onTouchEnd={handleTouchEnd}
//           >
//             <div
//               className="flex transition-transform duration-300 ease-in-out"
//               style={{
//                 transform: `translateX(-${activeTab * 100}%)`,
//                 width: `${services.length * 100}%`,
//               }}
//             >
//               {services.map((service, index) => (
//                 <div
//                   key={index}
//                   className="w-full flex-shrink-0 px-3 mobile:px-4"
//                 >
//                   <ServiceCard service={service} />
//                 </div>
//               ))}
//             </div>

//             {activeTab > 0 && (
//               <button
//                 onClick={() => setActiveTab((prev) => prev - 1)}
//                 className="absolute left-4 top-1/2 -translate-y-1/2 p-2 bg-white rounded-full shadow-lg z-10"
//               >
//                 <ChevronLeft className="w-5 h-5 text-gray-600" />
//               </button>
//             )}
//             {activeTab < services.length - 1 && (
//               <button
//                 onClick={() => setActiveTab((prev) => prev + 1)}
//                 className="absolute right-4 top-1/2 -translate-y-1/2 p-2 bg-white rounded-full shadow-lg z-10"
//               >
//                 <ChevronRight className="w-5 h-5 text-gray-600" />
//               </button>
//             )}
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Services;

// import React, { useState } from 'react';
// import {
//   Building,
//   UserCheck,
//   Shield,
//   GraduationCap,
//   Globe,
//   ChevronLeft,
//   ChevronRight,
// } from 'lucide-react';

// const Services = () => {
//   const [activeTab, setActiveTab] = useState(0);

//   const services = [
//     {
//       Icon: Building,
//       title: 'Enterprise Staffing',
//       description:
//         'Strategic talent acquisition for scaling organizations. Customized recruitment solutions tailored to your business needs.',
//       points: [
//         'Dedicated talent pools',
//         'Skill-specific recruitment',
//         'Customized hiring workflows',
//         'Compliance management',
//       ],
//     },
//     {
//       Icon: UserCheck,
//       title: 'Workforce Solutions',
//       description:
//         'End-to-end employee lifecycle management and support. Optimizing workforce performance through integrated services.',
//       points: [
//         'Contract staffing',
//         'Payroll management',
//         'Performance tracking',
//         'Resource optimization',
//       ],
//     },
//     {
//       Icon: Shield,
//       title: 'Quality Assurance',
//       description:
//         'Comprehensive verification and validation processes. Ensuring highest standards of talent quality and compliance.',
//       points: [
//         'Background verification',
//         'Skill assessment',
//         'Reference checks',
//         'Compliance documentation',
//       ],
//     },
//     {
//       Icon: GraduationCap,
//       title: 'Talent Development',
//       description:
//         'Custom training programs for workforce enhancement. Bridging skill gaps with targeted, adaptive learning solutions.',
//       points: [
//         'Skill gap analysis',
//         'Training implementation',
//         'Progress monitoring',
//         'Performance metrics',
//       ],
//     },
//     {
//       Icon: Globe,
//       title: 'Global Mobility',
//       description:
//         'International workforce management and relocation services. Seamless global talent movement with comprehensive support.',
//       points: [
//         'Visa processing',
//         'Relocation support',
//         'Cultural training',
//         'Global compliance',
//       ],
//     },
//   ];

//   const ActiveIcon = services[activeTab].Icon;

//   // Touch handling
//   const [touchStart, setTouchStart] = useState(null);
//   const [touchEnd, setTouchEnd] = useState(null);

//   const nextSlide = () => {
//     if (activeTab < services.length - 1) {
//       setActiveTab(activeTab + 1);
//     }
//   };

//   const prevSlide = () => {
//     if (activeTab > 0) {
//       setActiveTab(activeTab - 1);
//     }
//   };

//   const handleTouchStart = (e) => {
//     setTouchStart(e.touches[0].clientX);
//   };

//   const handleTouchMove = (e) => {
//     setTouchEnd(e.touches[0].clientX);
//   };

//   const handleTouchEnd = () => {
//     if (!touchStart || !touchEnd) return;
//     const distance = touchStart - touchEnd;
//     const isLeftSwipe = distance > 50;
//     const isRightSwipe = distance < -50;

//     if (isLeftSwipe && activeTab < services.length - 1) {
//       nextSlide();
//     }
//     if (isRightSwipe && activeTab > 0) {
//       prevSlide();
//     }

//     setTouchStart(null);
//     setTouchEnd(null);
//   };

//   return (
//     <section className="py-20 bg-gray-50">
//       <div className="max-w-7xl mx-auto px-4 sm:px-8 md:px-12 lg:px-8">
//         <div className="text-center mb-16">
//           <span className="text-blue-600 font-semibold text-sm uppercase tracking-wider">
//             For Enterprises
//           </span>
//           <h2 className="mt-2 text-3xl font-bold text-gray-900">
//             Comprehensive Business Solutions
//           </h2>
//           <p className="mt-4 text-gray-600 max-w-2xl mx-auto sm:px-6">
//             Streamline your workforce management with our end-to-end services
//             designed for modern enterprises
//           </p>
//         </div>

//         {/* Desktop View */}
//         <div className="hidden lg:flex lg:flex-row gap-8 h-full">
//           <div className="lg:w-1/4 h-full">
//             <div className="sticky top-24 space-y-2 h-full">
//               {services.map((service, index) => {
//                 const ServiceIcon = service.Icon;
//                 return (
//                   <button
//                     key={index}
//                     onClick={() => setActiveTab(index)}
//                     className={`w-full flex items-center gap-3 p-4 rounded-lg text-left transition-all duration-300 ${
//                       activeTab === index
//                         ? 'bg-blue-600 text-white shadow-lg'
//                         : 'bg-white text-gray-700 hover:bg-gray-100'
//                     }`}
//                   >
//                     <ServiceIcon className="w-5 h-5" />
//                     <span className="font-medium">{service.title}</span>
//                   </button>
//                 );
//               })}
//             </div>
//           </div>

//           <div className="lg:w-3/4">
//             <div className="bg-white rounded-xl p-8 shadow-lg h-full">
//               <div className="flex items-center gap-4 mb-6">
//                 <div className="bg-blue-500/10 p-3 rounded-lg">
//                   <ActiveIcon className="w-6 h-6 text-blue-600" />
//                 </div>
//                 <h3 className="text-2xl font-bold text-gray-900">
//                   {services[activeTab].title}
//                 </h3>
//               </div>

//               <p className="text-gray-600 text-lg mb-6 whitespace-pre-line">
//                 {services[activeTab].description}
//               </p>

//               <div className="grid sm:grid-cols-2 gap-4">
//                 {services[activeTab].points.map((point, index) => (
//                   <div
//                     key={index}
//                     className="flex items-center gap-2 p-4 bg-gray-50 rounded-lg"
//                   >
//                     <div className="w-1 h-1 rounded-full bg-blue-500" />
//                     <span className="text-gray-700">{point}</span>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Mobile View */}
//         <div className="lg:hidden">
//           {/* Service title and navigation dots */}
//           <div className="flex flex-col items-center mb-2">
//             <div className="flex gap-2 mb-4">
//               {services.map((_, index) => (
//                 <div
//                   key={index}
//                   className={`w-2 h-2 rounded-full transition-all duration-300 ${
//                     activeTab === index ? 'w-4 bg-blue-600' : 'bg-gray-300'
//                   }`}
//                 />
//               ))}
//             </div>
//           </div>

//           {/* Swipeable content */}
//           <div
//             className="relative w-full overflow-hidden px-4 sm:px-8"
//             onTouchStart={handleTouchStart}
//             onTouchMove={handleTouchMove}
//             onTouchEnd={handleTouchEnd}
//           >
//             <div
//               className="flex transition-transform duration-300 ease-in-out"
//               style={{
//                 transform: `translateX(-${activeTab * 100}%)`,
//                 width: `${services.length * 100}%`,
//               }}
//             >
//               {services.map((service, index) => (
//                 <div
//                   key={index}
//                   className="w-full px-2"
//                   style={{ flexBasis: 'calc(100% - 1rem)' }}
//                 >
//                   <div className="bg-white rounded-xl p-6 sm:p-8 shadow-lg border">
//                     <div className="flex items-center gap-4 mb-6">
//                       <div className="bg-blue-500/10 p-3 rounded-lg">
//                         <service.Icon className="w-6 h-6 text-blue-600" />
//                       </div>
//                       <h3 className="text-xl sm:text-2xl font-bold text-gray-900">
//                         {service.title}
//                       </h3>
//                     </div>
//                     <div className="w-full overflow-hidden mb-6">
//                       <p className="text-gray-600 text-base sm:text-lg break-words hyphens-auto">
//                         {service.description}
//                       </p>
//                     </div>
//                     <div className="grid gap-4">
//                       {service.points.map((point, idx) => (
//                         <div
//                           key={idx}
//                           className="flex items-center gap-2 p-4 bg-gray-50 rounded-lg"
//                         >
//                           <div className="w-1 h-1 rounded-full bg-blue-500" />
//                           <span className="text-gray-700 text-sm sm:text-base">
//                             {point}
//                           </span>
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>

//             {/* Navigation arrows */}
//             <div className="absolute inset-0 pointer-events-none">
//               {activeTab > 0 && (
//                 <button
//                   onClick={prevSlide}
//                   className="pointer-events-auto absolute left-0 top-1/2 -translate-y-1/2 p-2 bg-white rounded-full shadow-lg"
//                 >
//                   <ChevronLeft className="w-6 h-6 text-gray-600" />
//                 </button>
//               )}
//               {activeTab < services.length - 1 && (
//                 <button
//                   onClick={nextSlide}
//                   className="pointer-events-auto absolute right-0 top-1/2 -translate-y-1/2 p-2 bg-white rounded-full shadow-lg"
//                 >
//                   <ChevronRight className="w-6 h-6 text-gray-600" />
//                 </button>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Services;

// import React, { useState } from 'react';
// import {
//   Building,
//   UserCheck,
//   Shield,
//   GraduationCap,
//   Globe,
//   ChevronLeft,
//   ChevronRight,
// } from 'lucide-react';

// const Services = () => {
//   const [activeTab, setActiveTab] = useState(0);
//   const [touchStart, setTouchStart] = useState(0);
//   const [touchEnd, setTouchEnd] = useState(0);

//   const services = [
//     {
//       Icon: Building,
//       title: 'Enterprise Staffing',
//       description:
//         'Strategic talent acquisition for scaling organizations. Customized recruitment solutions tailored to your business needs.',
//       points: [
//         'Dedicated talent pools',
//         'Skill-specific recruitment',
//         'Customized hiring workflows',
//         'Compliance management',
//       ],
//     },
//     {
//       Icon: UserCheck,
//       title: 'Workforce Solutions',
//       description:
//         'End-to-end employee lifecycle management and support. Optimizing workforce performance through integrated services.',
//       points: [
//         'Contract staffing',
//         'Payroll management',
//         'Performance tracking',
//         'Resource optimization',
//       ],
//     },
//     {
//       Icon: Shield,
//       title: 'Quality Assurance',
//       description:
//         'Comprehensive verification and validation processes. Ensuring highest standards of talent quality and compliance.',
//       points: [
//         'Background verification',
//         'Skill assessment',
//         'Reference checks',
//         'Compliance documentation',
//       ],
//     },
//     {
//       Icon: GraduationCap,
//       title: 'Talent Development',
//       description:
//         'Custom training programs for workforce enhancement. Bridging skill gaps with targeted, adaptive learning solutions.',
//       points: [
//         'Skill gap analysis',
//         'Training implementation',
//         'Progress monitoring',
//         'Performance metrics',
//       ],
//     },
//     {
//       Icon: Globe,
//       title: 'Global Mobility',
//       description:
//         'International workforce management and relocation services. Seamless global talent movement with comprehensive support.',
//       points: [
//         'Visa processing',
//         'Relocation support',
//         'Cultural training',
//         'Global compliance',
//       ],
//     },
//   ];

//   const ActiveIcon = services[activeTab].Icon;

//   const minSwipeDistance = 50;

//   const nextSlide = () => {
//     if (activeTab < services.length - 1) {
//       setActiveTab(activeTab + 1);
//     }
//   };

//   const prevSlide = () => {
//     if (activeTab > 0) {
//       setActiveTab(activeTab - 1);
//     }
//   };

//   const handleTouchStart = (e) => {
//     setTouchEnd(0);
//     setTouchStart(e.touches[0].clientX);
//   };

//   const handleTouchMove = (e) => {
//     setTouchEnd(e.touches[0].clientX);
//   };

//   const handleTouchEnd = () => {
//     if (!touchStart || !touchEnd) return;

//     const distance = touchStart - touchEnd;
//     const isLeftSwipe = distance > minSwipeDistance;
//     const isRightSwipe = distance < -minSwipeDistance;

//     if (isLeftSwipe) {
//       nextSlide();
//     }
//     if (isRightSwipe) {
//       prevSlide();
//     }
//   };

//   return (
//     <section className="py-20 bg-gray-50">
//       <div className="max-w-7xl mx-auto px-4 sm:px-8 md:px-12 lg:px-8">
//         <div className="text-center mb-16">
//           <span className="text-blue-600 font-semibold text-sm uppercase tracking-wider">
//             For Enterprises
//           </span>
//           <h2 className="mt-2 text-3xl font-bold text-gray-900">
//             Comprehensive Business Solutions
//           </h2>
//           <p className="mt-4 text-gray-600 max-w-2xl mx-auto sm:px-6">
//             Streamline your workforce management with our end-to-end services
//             designed for modern enterprises
//           </p>
//         </div>

//         {/* Desktop View */}
//         <div className="hidden lg:flex lg:flex-row gap-8 h-full">
//           <div className="lg:w-1/4 h-full">
//             <div className="sticky top-24 space-y-2">
//               {services.map((service, index) => {
//                 const ServiceIcon = service.Icon;
//                 return (
//                   <button
//                     key={index}
//                     onClick={() => setActiveTab(index)}
//                     className={`w-full flex items-center gap-3 p-4 rounded-lg text-left transition-all duration-300 ${
//                       activeTab === index
//                         ? 'bg-blue-600 text-white shadow-lg'
//                         : 'bg-white text-gray-700 hover:bg-gray-100'
//                     }`}
//                   >
//                     <ServiceIcon className="w-5 h-5" />
//                     <span className="font-medium">{service.title}</span>
//                   </button>
//                 );
//               })}
//             </div>
//           </div>

//           <div className="lg:w-3/4">
//             <div className="bg-white rounded-xl p-8 shadow-lg">
//               <div className="flex items-center gap-4 mb-6">
//                 <div className="bg-blue-500/10 p-3 rounded-lg">
//                   <ActiveIcon className="w-6 h-6 text-blue-600" />
//                 </div>
//                 <h3 className="text-2xl font-bold text-gray-900">
//                   {services[activeTab].title}
//                 </h3>
//               </div>

//               <p className="text-gray-600 text-lg mb-6">
//                 {services[activeTab].description}
//               </p>

//               <div className="grid sm:grid-cols-2 gap-4">
//                 {services[activeTab].points.map((point, index) => (
//                   <div
//                     key={index}
//                     className="flex items-center gap-2 p-4 bg-gray-50 rounded-lg"
//                   >
//                     <div className="w-1 h-1 rounded-full bg-blue-500" />
//                     <span className="text-gray-700">{point}</span>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Mobile View */}
//         <div className="lg:hidden w-full overflow-hidden">
//           <div className="flex flex-col items-center mb-2">
//             <div className="flex gap-2 mb-4">
//               {services.map((_, index) => (
//                 <div
//                   key={index}
//                   className={`w-2 h-2 rounded-full transition-all duration-300 ${
//                     activeTab === index ? 'w-4 bg-blue-600' : 'bg-gray-300'
//                   }`}
//                 />
//               ))}
//             </div>
//           </div>

//           <div
//             className="relative w-full touch-pan-y overflow-hidden"
//             onTouchStart={handleTouchStart}
//             onTouchMove={handleTouchMove}
//             onTouchEnd={handleTouchEnd}
//           >
//             <div
//               className="flex transition-transform duration-300 ease-in-out"
//               style={{
//                 transform: `translateX(-${activeTab * 100}%)`,
//                 width: `${services.length * 100}%`,
//               }}
//             >
//               {services.map((service, index) => (
//                 <div key={index} className="w-full flex-shrink-0">
//                   <div className="bg-white rounded-xl p-6 sm:p-8 shadow-lg border mx-2">
//                     <div className="flex items-center gap-4 mb-6">
//                       <div className="bg-blue-500/10 p-3 rounded-lg">
//                         <service.Icon className="w-6 h-6 text-blue-600" />
//                       </div>
//                       <h3 className="text-xl sm:text-2xl font-bold text-gray-900">
//                         {service.title}
//                       </h3>
//                     </div>
//                     <div className="mb-6">
//                       <p className="text-gray-600 text-base sm:text-lg">
//                         {service.description}
//                       </p>
//                     </div>
//                     <div className="grid gap-4">
//                       {service.points.map((point, idx) => (
//                         <div
//                           key={idx}
//                           className="flex items-center gap-2 p-4 bg-gray-50 rounded-lg"
//                         >
//                           <div className="w-1 h-1 rounded-full bg-blue-500" />
//                           <span className="text-gray-700 text-sm sm:text-base">
//                             {point}
//                           </span>
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>

//             <div className="absolute inset-0 pointer-events-none">
//               {activeTab > 0 && (
//                 <button
//                   onClick={prevSlide}
//                   className="pointer-events-auto absolute left-2 top-1/2 -translate-y-1/2 p-2 bg-white rounded-full shadow-lg"
//                 >
//                   <ChevronLeft className="w-6 h-6 text-gray-600" />
//                 </button>
//               )}
//               {activeTab < services.length - 1 && (
//                 <button
//                   onClick={nextSlide}
//                   className="pointer-events-auto absolute right-2 top-1/2 -translate-y-1/2 p-2 bg-white rounded-full shadow-lg"
//                 >
//                   <ChevronRight className="w-6 h-6 text-gray-600" />
//                 </button>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Services;

import React, { useState } from 'react';
import {
  Building,
  UserCheck,
  Shield,
  GraduationCap,
  Globe,
  ChevronLeft,
  ChevronRight,
} from 'lucide-react';

const Services = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);
  const [startX, setStartX] = useState(0);
  const [currentTranslate, setCurrentTranslate] = useState(0);

  const services = [
    {
      Icon: Building,
      title: 'Enterprise Staffing',
      description:
        'Strategic talent acquisition for scaling organizations. Customized recruitment solutions tailored to your business needs.',
      points: [
        'Dedicated talent pools',
        'Skill-specific recruitment',
        'Customized hiring workflows',
        'Compliance management',
      ],
    },
    {
      Icon: UserCheck,
      title: 'Workforce Solutions',
      description:
        'End-to-end employee lifecycle management and support. Optimizing workforce performance through integrated services.',
      points: [
        'Contract staffing',
        'Payroll management',
        'Performance tracking',
        'Resource optimization',
      ],
    },
    {
      Icon: Shield,
      title: 'Quality Assurance',
      description:
        'Comprehensive verification and validation processes. Ensuring highest standards of talent quality and compliance.',
      points: [
        'Background verification',
        'Skill assessment',
        'Reference checks',
        'Compliance documentation',
      ],
    },
    {
      Icon: GraduationCap,
      title: 'Talent Development',
      description:
        'Custom training programs for workforce enhancement. Bridging skill gaps with targeted, adaptive learning solutions.',
      points: [
        'Skill gap analysis',
        'Training implementation',
        'Progress monitoring',
        'Performance metrics',
      ],
    },
    {
      Icon: Globe,
      title: 'Global Mobility',
      description:
        'International workforce management and relocation services. Seamless global talent movement with comprehensive support.',
      points: [
        'Visa processing',
        'Relocation support',
        'Cultural training',
        'Global compliance',
      ],
    },
  ];

  const ActiveIcon = services[activeTab].Icon;

  const handleNext = () => {
    if (activeTab < services.length - 1) {
      setActiveTab((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    if (activeTab > 0) {
      setActiveTab((prev) => prev - 1);
    }
  };

  const handleTouchStart = (e) => {
    setIsSwiping(true);
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!isSwiping) return;

    const currentX = e.touches[0].clientX;
    const diff = currentX - startX;
    const sensitivity = 0.5; // Reduce this number to make swipe less sensitive

    // Only allow swipe if it's not at the edges, or if it's at an edge and swiping in the allowed direction
    if (
      (activeTab > 0 || diff < 0) && // Allow right-to-left swipe if not at first slide
      (activeTab < services.length - 1 || diff > 0) // Allow left-to-right swipe if not at last slide
    ) {
      setCurrentTranslate(diff * sensitivity);
    }
  };

  const handleTouchEnd = () => {
    setIsSwiping(false);

    // If the swipe was significant enough, change slides
    if (Math.abs(currentTranslate) > 50) {
      if (currentTranslate > 0) {
        handlePrev();
      } else {
        handleNext();
      }
    }

    setCurrentTranslate(0);
  };

  const MobileCard = ({ service }) => {
    const ServiceIcon = service.Icon;

    return (
      <div className="bg-white rounded-xl p-6 shadow-lg">
        <div className="flex items-center gap-4 mb-4">
          <div className="bg-blue-500/10 p-3 rounded-lg">
            <ServiceIcon className="w-6 h-6 text-blue-600" />
          </div>
          <h3 className="text-xl font-bold text-gray-900">{service.title}</h3>
        </div>
        <p className="text-gray-600 mb-6">{service.description}</p>
        <div className="space-y-3">
          {service.points.map((point, idx) => (
            <div
              key={idx}
              className="flex items-center gap-2 p-3 bg-gray-50 rounded-lg"
            >
              <div className="w-1 h-1 rounded-full bg-blue-500" />
              <span className="text-gray-700 text-sm">{point}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <section className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4">
        {/* Header */}
        <div className="text-center mb-12">
          <span className="text-blue-600 font-semibold text-sm uppercase tracking-wider">
            For Enterprises
          </span>
          <h2 className="mt-2 text-3xl font-bold text-gray-900">
            Comprehensive Business Solutions
          </h2>
          <p className="mt-4 text-gray-600 max-w-2xl mx-auto">
            Streamline your workforce management with our end-to-end services
            designed for modern enterprises
          </p>
        </div>

        {/* Desktop View */}
        <div className="hidden lg:flex gap-8">
          <div className="w-1/4">
            <div className="sticky top-24 space-y-2">
              {services.map((service, index) => {
                const ServiceIcon = service.Icon;
                return (
                  <button
                    key={index}
                    onClick={() => setActiveTab(index)}
                    className={`w-full flex items-center gap-3 p-4 rounded-lg text-left transition-all duration-300 ${
                      activeTab === index
                        ? 'bg-blue-600 text-white shadow-lg'
                        : 'bg-white text-gray-700 hover:bg-gray-100'
                    }`}
                  >
                    <ServiceIcon className="w-5 h-5" />
                    <span className="font-medium">{service.title}</span>
                  </button>
                );
              })}
            </div>
          </div>

          <div className="w-3/4">
            <div className="bg-white rounded-xl p-8 shadow-lg">
              <div className="flex items-center gap-4 mb-6">
                <div className="bg-blue-500/10 p-3 rounded-lg">
                  <ActiveIcon className="w-6 h-6 text-blue-600" />
                </div>
                <h3 className="text-2xl font-bold text-gray-900">
                  {services[activeTab].title}
                </h3>
              </div>

              <p className="text-gray-600 text-lg mb-6">
                {services[activeTab].description}
              </p>

              <div className="grid sm:grid-cols-2 gap-4">
                {services[activeTab].points.map((point, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-2 p-4 bg-gray-50 rounded-lg"
                  >
                    <div className="w-1 h-1 rounded-full bg-blue-500" />
                    <span className="text-gray-700">{point}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Mobile View */}
        <div className="lg:hidden">
          {/* Progress dots */}
          <div className="flex justify-center gap-2 mb-6 z-10">
            {services.map((_, index) => (
              <button
                key={index}
                onClick={() => setActiveTab(index)}
                className={`h-2 rounded-full transition-all duration-300 ${
                  activeTab === index ? 'w-6 bg-blue-600' : 'w-2 bg-gray-300'
                }`}
              />
            ))}
          </div>

          {/* Carousel container */}
          <div className="relative overflow-hidden rounded-xl z-50">
            <div
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              className="relative w-full transition-transform duration-300 ease-out"
              style={{
                transform: `translateX(${-activeTab * 100 + (currentTranslate / window.innerWidth) * 100}%)`,
              }}
            >
              <div className="flex">
                {services.map((service, index) => (
                  <div key={index} className="w-full flex-shrink-0 px-1">
                    <MobileCard service={service} />
                  </div>
                ))}
              </div>
            </div>

            {/* Navigation buttons */}
            {activeTab > 0 && (
              <button
                onClick={handlePrev}
                className="absolute left-3 top-1/2 -translate-y-1/2 p-2 bg-white rounded-full shadow-lg z-10"
                aria-label="Previous slide"
              >
                <ChevronLeft className="w-6 h-6 text-gray-600" />
              </button>
            )}
            {activeTab < services.length - 1 && (
              <button
                onClick={handleNext}
                className="absolute right-3 top-1/2 -translate-y-1/2 p-2 bg-white rounded-full shadow-lg z-10"
                aria-label="Next slide"
              >
                <ChevronRight className="w-6 h-6 text-gray-600" />
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
