import React, { useState } from 'react';
import * as Icons from 'lucide-react';
import useIsMobile from '../../../hooks/useIsMobile';
import BottomSheet from './BottomSheet';

const SelectionView = ({
  marketData,
  selectedIndustry,
  selectedSubIndustry,
  selectedRole,
  onSelectionChange,
}) => {
  const isMobile = useIsMobile();
  const [activeSheet, setActiveSheet] = useState(null);

  const getIcon = (iconName) => {
    const Icon = Icons[iconName];
    return Icon ? <Icon className="w-5 h-5" /> : null;
  };

  const getSheetOptions = () => {
    switch (activeSheet) {
      case 'industry':
        return Object.keys(marketData).map((industry) => ({
          value: industry,
          label: industry,
          icon: marketData[industry].iconName,
        }));
      case 'subIndustry':
        return selectedIndustry
          ? Object.keys(marketData[selectedIndustry].subIndustries).map(
              (sub) => ({ value: sub, label: sub })
            )
          : [];
      case 'role':
        return selectedIndustry && selectedSubIndustry
          ? Object.keys(
              marketData[selectedIndustry].subIndustries[selectedSubIndustry]
                .roles
            ).map((role) => ({ value: role, label: role }))
          : [];
      default:
        return [];
    }
  };

  // Mobile view remains unchanged

  const MobileView = () => (
    <div className="space-y-3 p-4">
      {/* Mobile selections - unchanged */}
      <button
        onClick={() => setActiveSheet('industry')}
        className="w-full text-left p-4 bg-white rounded-lg shadow-sm"
      >
        <p className="text-sm text-gray-500 mb-1">Industry</p>
        <div className="flex items-center justify-between">
          <span className="text-base font-medium text-gray-900">
            {selectedIndustry || 'Select Industry'}
          </span>
          <Icons.ChevronUp className="w-5 h-5 text-gray-400" />
        </div>
      </button>

      {selectedIndustry ? (
        <div className="animate-fadeIn">
          <button
            onClick={() => setActiveSheet('subIndustry')}
            className="w-full text-left p-4 bg-white rounded-lg shadow-sm"
          >
            <p className="text-sm text-gray-500 mb-1">Specialization</p>
            <div className="flex items-center justify-between">
              <span className="text-base font-medium text-gray-900">
                {selectedSubIndustry || 'Select Specialization'}
              </span>
              <Icons.ChevronUp className="w-5 h-5 text-gray-400" />
            </div>
          </button>
        </div>
      ) : null}

      {selectedIndustry && selectedSubIndustry ? (
        <div className="animate-fadeIn">
          <button
            onClick={() => setActiveSheet('role')}
            className="w-full text-left p-4 bg-white rounded-lg shadow-sm"
          >
            <p className="text-sm text-gray-500 mb-1">Role</p>
            <div className="flex items-center justify-between">
              <span className="text-base font-medium text-gray-900">
                {selectedRole || 'Select Role'}
              </span>
              <Icons.ChevronUp className="w-5 h-5 text-gray-400" />
            </div>
          </button>
        </div>
      ) : null}

      <BottomSheet
        isOpen={!!activeSheet}
        onClose={() => setActiveSheet(null)}
        title={
          activeSheet === 'industry'
            ? 'Select Industry'
            : activeSheet === 'subIndustry'
              ? 'Select Specialization'
              : 'Select Role'
        }
      >
        <div className="space-y-1">
          {getSheetOptions().map((option) => (
            <button
              key={option.value}
              onClick={() => {
                onSelectionChange(activeSheet, option.value);
                setActiveSheet(null);
              }}
              className="w-full text-left px-4 py-3 rounded-lg flex items-center gap-3 transition-colors hover:bg-gray-50"
            >
              {option.icon && (
                <div className="p-2 rounded-lg bg-blue-50 text-blue-600">
                  {getIcon(option.icon)}
                </div>
              )}
              <span
                className={
                  option.value ===
                  (activeSheet === 'industry'
                    ? selectedIndustry
                    : activeSheet === 'subIndustry'
                      ? selectedSubIndustry
                      : selectedRole)
                    ? 'text-blue-700 font-medium'
                    : 'text-gray-700'
                }
              >
                {option.label}
              </span>
            </button>
          ))}
        </div>
      </BottomSheet>
    </div>
  );

  const DesktopView = () => (
    <div className="w-80 bg-white rounded-xl border border-gray-200 h-[500px] flex flex-col">
      <div className="flex-1 overflow-y-auto">
        {!selectedIndustry ? (
          <div className="h-full p-2 flex border rounded-xl flex-col justify-center">
            {Object.entries(marketData).map(([industry, data]) => (
              <button
                key={industry}
                onClick={() => onSelectionChange('industry', industry)}
                className="w-full rounded-xl flex items-center m-[1px] justify-between p-3 hover:bg-gray-50 border-y border-gray-100 "
              >
                <div className="flex items-center gap-3">
                  <div className="p-2 rounded-lg bg-blue-50 text-blue-600">
                    {getIcon(data.iconName)}
                  </div>
                  <span className="font-medium text-gray-900 text-base">
                    {industry}
                  </span>
                </div>
                <Icons.ChevronRight className="w-5 h-5 text-gray-400" />
              </button>
            ))}
          </div>
        ) : (
          <div>
            {Object.entries(marketData).map(([industry, data]) => (
              <div key={industry}>
                <button
                  onClick={() => onSelectionChange('industry', industry)}
                  className={`w-full flex rounded-xl items-center justify-between p-3 hover:bg-gray-50 border-b border-gray-100
                    ${selectedIndustry === industry ? 'bg-blue-50' : ''}`}
                >
                  <div className="flex items-center gap-3">
                    <div className="p-2 rounded-lg bg-blue-50 text-blue-600">
                      {getIcon(data.iconName)}
                    </div>
                    <span className="font-medium text-gray-900 text-base">
                      {industry}
                    </span>
                  </div>
                  {getIcon(
                    selectedIndustry === industry
                      ? 'ChevronDown'
                      : 'ChevronRight'
                  )}
                </button>

                {selectedIndustry === industry && (
                  <div className="bg-white">
                    {Object.entries(data.subIndustries).map(
                      ([subIndustry, subData]) => (
                        <div key={subIndustry}>
                          <button
                            onClick={() =>
                              onSelectionChange('subIndustry', subIndustry)
                            }
                            className={`w-full text-left px-6 py-3 hover:bg-gray-50 border-b border-gray-100
                              ${selectedSubIndustry === subIndustry ? 'bg-blue-100 text-blue-700' : 'text-gray-700'}`}
                          >
                            <span className="text-base">{subIndustry}</span>
                          </button>

                          {selectedSubIndustry === subIndustry && (
                            <div className="border-b border-gray-100">
                              {Object.keys(subData.roles).map((role) => (
                                <button
                                  key={role}
                                  onClick={() =>
                                    onSelectionChange('role', role)
                                  }
                                  className={`w-full text-left px-8 py-3 hover:bg-gray-50 border-b border-gray-100
                                    ${selectedRole === role ? 'bg-blue-200 text-blue-700' : 'text-gray-700'}`}
                                >
                                  <span className="text-base">{role}</span>
                                </button>
                              ))}
                            </div>
                          )}
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  return isMobile ? <MobileView /> : <DesktopView />;
};

export default SelectionView;
