import React from 'react';
import { ArrowRight, Globe, CheckCircle, Users } from 'lucide-react';

const EmployerCTA = () => {
  const benefits = [
    {
      icon: <Globe className="w-5 h-5 text-blue-600" />,
      text: 'Access to pre-vetted global talent pool',
    },
    {
      icon: <CheckCircle className="w-5 h-5 text-blue-600" />,
      text: 'Complete compliance and visa support',
    },
    {
      icon: <Users className="w-5 h-5 text-blue-600" />,
      text: 'Dedicated account management team',
    },
  ];

  return (
    <section className="relative py-20 overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-white" />
      <div className="absolute inset-0 bg-[radial-gradient(45%_45%_at_50%_50%,transparent_50%,#f1f5f9_100%)]" />

      <div className="relative max-w-7xl mx-auto px-6">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          {/* Content Side */}
          <div className="space-y-8">
            <div className="space-y-4">
              <span className="inline-block px-3 py-1 text-sm font-semibold text-blue-600 bg-blue-50 rounded-full">
                For Employers
              </span>
              <h2 className="text-4xl font-bold text-gray-900">
                Ready to Access Global Talent?
              </h2>
              <p className="text-xl text-gray-600">
                Start building your international team with our end-to-end
                hiring solutions. Get matched with the right talent within
                weeks.
              </p>
            </div>

            <div className="space-y-4">
              {benefits.map((benefit, index) => (
                <div key={index} className="flex items-center gap-3">
                  <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-white shadow-sm flex items-center justify-center">
                    {benefit.icon}
                  </div>
                  <span className="text-gray-700">{benefit.text}</span>
                </div>
              ))}
            </div>

            <div className="flex flex-col sm:flex-row gap-4">
              <button
                onClick={() =>
                  (window.location.href = 'https://forms.gle/Ao4qYLS95bXScZ7k8')
                }
                className="inline-flex items-center justify-center px-6 py-3 rounded-lg bg-blue-600 text-white font-semibold hover:bg-blue-700 transition-colors duration-200"
              >
                Submit Your Requirements
                <ArrowRight className="ml-2 w-4 h-4" />
              </button>
              {/* <button className="inline-flex items-center justify-center px-6 py-3 rounded-lg border border-gray-300 text-gray-700 font-semibold hover:bg-gray-50 transition-colors duration-200">
                View Pricing
              </button> */}
            </div>
          </div>

          {/* Stats Side */}
          <div className="bg-white rounded-2xl p-8 shadow-lg border border-gray-100">
            <div className="grid grid-cols-2 gap-8">
              <div className="text-center p-6 bg-gray-50 rounded-xl">
                <div className="text-4xl font-bold text-blue-600 mb-2">15</div>
                <div className="text-sm text-gray-600">
                  Days Average
                  <br />
                  To Find a Candidate
                </div>
              </div>
              <div className="text-center p-6 bg-gray-50 rounded-xl">
                <div className="text-4xl font-bold text-blue-600 mb-2">4+</div>
                <div className="text-sm text-gray-600">
                  Countries
                  <br />
                  Covered
                </div>
              </div>
              <div className="text-center p-6 bg-gray-50 rounded-xl">
                <div className="text-4xl font-bold text-blue-600 mb-2">95%</div>
                <div className="text-sm text-gray-600">
                  Client
                  <br />
                  Satisfaction
                </div>
              </div>
              <div className="text-center p-6 bg-gray-50 rounded-xl">
                <div className="text-4xl font-bold text-blue-600 mb-2">
                  100+
                </div>
                <div className="text-sm text-gray-600">
                  Successful
                  <br />
                  Placements
                </div>
              </div>
            </div>

            {/* <div className="mt-8 p-6 bg-blue-50 rounded-xl">
              <div className="text-sm font-medium text-blue-600 mb-2">
                TRUSTED BY LEADING COMPANIES
              </div>
              <div className="grid grid-cols-3 gap-4">
                {[1, 2, 3].map((_, i) => (
                  <div
                    key={i}
                    className="h-8 bg-gray-200 rounded animate-pulse"
                  />
                ))}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmployerCTA;

// import React from 'react';
// import { ArrowRight, Globe, CheckCircle, Users } from 'lucide-react';

// const EmployerCTA = () => {
//   const benefits = [
//     {
//       icon: <Globe className="w-5 h-5 text-blue-600" />,
//       text: 'Access to pre-vetted global talent pool',
//     },
//     {
//       icon: <CheckCircle className="w-5 h-5 text-blue-600" />,
//       text: 'Complete compliance and visa support',
//     },
//     {
//       icon: <Users className="w-5 h-5 text-blue-600" />,
//       text: 'Dedicated account management team',
//     },
//   ];

//   return (
//     <section className="relative py-20 overflow-hidden">
//       {/* Background Pattern */}
//       <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-white" />
//       <div className="absolute inset-0 bg-[radial-gradient(45%_45%_at_50%_50%,transparent_50%,#f1f5f9_100%)]" />

//       <div className="relative max-w-7xl mx-auto px-6">
//         <div className="grid lg:grid-cols-2 gap-12 items-center">
//           {/* Content Side */}
//           <div className="space-y-8">
//             <div className="space-y-4">
//               <span className="inline-block px-3 py-1 text-sm font-semibold text-blue-600 bg-blue-50 rounded-full">
//                 For Employers
//               </span>
//               <h2 className="text-4xl font-bold text-gray-900">
//                 Ready to Access Global Talent?
//               </h2>
//               <p className="text-xl text-gray-600">
//                 Start building your international team with our end-to-end
//                 hiring solutions. Get matched with the right talent within
//                 weeks.
//               </p>
//             </div>

//             <div className="space-y-4">
//               {benefits.map((benefit, index) => (
//                 <div key={index} className="flex items-center gap-3">
//                   <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-white shadow-sm flex items-center justify-center">
//                     {benefit.icon}
//                   </div>
//                   <span className="text-gray-700">{benefit.text}</span>
//                 </div>
//               ))}
//             </div>

//             <div className="flex flex-col sm:flex-row gap-4">
//               <button className="inline-flex items-center justify-center px-6 py-3 rounded-lg bg-blue-600 text-white font-semibold hover:bg-blue-700 transition-colors duration-200">
//                 Schedule a Consultation
//                 <ArrowRight className="ml-2 w-4 h-4" />
//               </button>
//               <button className="inline-flex items-center justify-center px-6 py-3 rounded-lg border border-gray-300 text-gray-700 font-semibold hover:bg-gray-50 transition-colors duration-200">
//                 View Pricing
//               </button>
//             </div>
//           </div>

//           {/* Stats Side */}
//           <div className="bg-white rounded-2xl p-8 shadow-lg border border-gray-100">
//             <div className="grid grid-cols-2 gap-8">
//               <div className="text-center p-6 bg-gray-50 rounded-xl">
//                 <div className="text-4xl font-bold text-blue-600 mb-2">15</div>
//                 <div className="text-sm text-gray-600">
//                   Days Average
//                   <br />
//                   Placement Time
//                 </div>
//               </div>
//               <div className="text-center p-6 bg-gray-50 rounded-xl">
//                 <div className="text-4xl font-bold text-blue-600 mb-2">
//                   200+
//                 </div>
//                 <div className="text-sm text-gray-600">
//                   Countries
//                   <br />
//                   Covered
//                 </div>
//               </div>
//               <div className="text-center p-6 bg-gray-50 rounded-xl">
//                 <div className="text-4xl font-bold text-blue-600 mb-2">98%</div>
//                 <div className="text-sm text-gray-600">
//                   Client
//                   <br />
//                   Satisfaction
//                 </div>
//               </div>
//               <div className="text-center p-6 bg-gray-50 rounded-xl">
//                 <div className="text-4xl font-bold text-blue-600 mb-2">
//                   10k+
//                 </div>
//                 <div className="text-sm text-gray-600">
//                   Successful
//                   <br />
//                   Placements
//                 </div>
//               </div>
//             </div>

//             <div className="mt-8 p-6 bg-blue-50 rounded-xl">
//               <div className="text-sm font-medium text-blue-600 mb-2">
//                 TRUSTED BY LEADING COMPANIES
//               </div>
//               <div className="grid grid-cols-3 gap-4">
//                 {[1, 2, 3].map((_, i) => (
//                   <div
//                     key={i}
//                     className="h-8 bg-gray-200 rounded animate-pulse"
//                   />
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default EmployerCTA;
