import { useEffect } from 'react';

const usePageMounting = () => {
  useEffect(() => {
    const handlePageShow = (event) => {
      if (event.persisted) {
        console.log('Page loaded from bfcache');
      }
    };

    const handlePopState = () => {
      console.log('Navigating to:', window.location.pathname);
    };

    // Add event listeners
    window.addEventListener('pageshow', handlePageShow);
    window.addEventListener('popstate', handlePopState);

    // Cleanup listeners on unmount
    return () => {
      window.removeEventListener('pageshow', handlePageShow);
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);
};

export default usePageMounting;
