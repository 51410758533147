// components/StickyBanner.jsx
import React from 'react';
import { ArrowRight } from 'lucide-react';

const StickyBanner = () => {
  return (
    <div className="bg-teal-600/95 backdrop-blur-sm mx-auto max-w-5xl rounded-full shadow-lg">
      <div className="flex items-center justify-center space-x-3 py-2 px-6">
        <span className="animate-bounce text-xl">🚀</span>
        <p className="text-white text-sm font-medium">
          Launching our flagship Path to Germany program!
        </p>
        <a
          href="https://path-to-germany.talentfirst.global"
          className="inline-flex items-center text-white text-sm font-semibold hover:text-teal-100 transition-colors"
        >
          Learn More
          <ArrowRight className="w-4 h-4 ml-1" />
        </a>
      </div>
    </div>
  );
};

export default StickyBanner;
