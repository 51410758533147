// marketData.js

const marketData = {
  'Healthcare & Medical': {
    iconName: 'Stethoscope',
    subIndustries: {
      'Medical Devices': {
        roles: {
          'R&D Engineer': {
            Germany: {
              demandLevel: 'Very High',
              averageSalary: '€55,000 - €75,000',
              visaProcess: 'EU Blue Card eligible, fast processing',
              keyRequirements: [
                'Medical device design experience',
                'ISO 13485 knowledge',
                'Regulatory compliance experience',
                'Product development lifecycle',
              ],
              cities: ['Munich', 'Hamburg', 'Stuttgart'],
            },
            Netherlands: {
              demandLevel: 'High',
              averageSalary: '€50,000 - €70,000',
              visaProcess: 'Highly skilled migrant visa',
              keyRequirements: [
                'Medical technology background',
                'CE marking knowledge',
                'Quality systems expertise',
              ],
              cities: ['Amsterdam', 'Eindhoven', 'Rotterdam'],
            },
          },
          'Quality Assurance Manager': {
            Germany: {
              demandLevel: 'High',
              averageSalary: '€60,000 - €80,000',
              visaProcess: 'EU Blue Card eligible',
              keyRequirements: [
                'QMS implementation',
                'ISO 13485 certification',
                'Risk management',
                'Audit experience',
              ],
              cities: ['Berlin', 'Frankfurt', 'Munich'],
            },
          },
        },
      },
      Pharmaceuticals: {
        roles: {
          'Process Engineer': {
            Germany: {
              demandLevel: 'Very High',
              averageSalary: '€60,000 - €85,000',
              visaProcess: 'EU Blue Card preferred',
              keyRequirements: [
                'GMP knowledge',
                'Process optimization',
                'Validation experience',
              ],
              cities: ['Frankfurt', 'Berlin', 'Heidelberg'],
            },
          },
        },
      },
    },
  },

  'Technology & IT': {
    iconName: 'Laptop',
    subIndustries: {
      'Software Development': {
        roles: {
          'Full Stack Developer': {
            Germany: {
              demandLevel: 'Very High',
              averageSalary: '€65,000 - €85,000',
              visaProcess: 'EU Blue Card eligible',
              keyRequirements: [
                'Modern JavaScript frameworks',
                'Cloud platforms',
                'System architecture',
                'API development',
              ],
              cities: ['Berlin', 'Munich', 'Hamburg'],
            },
          },
          'DevOps Engineer': {
            Netherlands: {
              demandLevel: 'Very High',
              averageSalary: '€60,000 - €80,000',
              visaProcess: 'Knowledge migrant visa',
              keyRequirements: [
                'CI/CD pipelines',
                'Cloud services (AWS/Azure)',
                'Kubernetes',
                'Infrastructure as Code',
              ],
              cities: ['Amsterdam', 'Rotterdam', 'Utrecht'],
            },
          },
        },
      },
      'Data Science': {
        roles: {
          'Data Scientist': {
            Germany: {
              demandLevel: 'High',
              averageSalary: '€60,000 - €80,000',
              visaProcess: 'EU Blue Card eligible',
              keyRequirements: [
                'Machine Learning',
                'Python/R',
                'Statistical Analysis',
                'Big Data Technologies',
              ],
              cities: ['Berlin', 'Munich', 'Frankfurt'],
            },
          },
        },
      },
    },
  },

  'Manufacturing & Industry': {
    iconName: 'Factory',
    subIndustries: {
      Automotive: {
        roles: {
          'Production Engineer': {
            Germany: {
              demandLevel: 'Very High',
              averageSalary: '€52,000 - €72,000',
              visaProcess: 'EU Blue Card eligible',
              keyRequirements: [
                'Lean manufacturing',
                'Industry 4.0',
                'Quality control methods',
                'Process optimization',
              ],
              cities: ['Stuttgart', 'Munich', 'Wolfsburg'],
            },
          },
          'Quality Manager': {
            Germany: {
              demandLevel: 'High',
              averageSalary: '€55,000 - €75,000',
              visaProcess: 'EU Blue Card eligible',
              keyRequirements: [
                'ISO/TS 16949',
                'Quality management systems',
                'Audit experience',
              ],
              cities: ['Stuttgart', 'Munich', 'Wolfsburg'],
            },
          },
        },
      },
      'Industrial Automation': {
        roles: {
          'Automation Engineer': {
            Germany: {
              demandLevel: 'Very High',
              averageSalary: '€55,000 - €75,000',
              visaProcess: 'EU Blue Card eligible',
              keyRequirements: [
                'PLC Programming',
                'SCADA Systems',
                'Industrial IoT',
                'Robot Programming',
              ],
              cities: ['Stuttgart', 'Hamburg', 'Munich'],
            },
          },
        },
      },
    },
  },

  'Energy & Utilities': {
    iconName: 'Zap',
    subIndustries: {
      'Renewable Energy': {
        roles: {
          'Wind Energy Engineer': {
            Germany: {
              demandLevel: 'Very High',
              averageSalary: '€55,000 - €75,000',
              visaProcess: 'EU Blue Card eligible',
              keyRequirements: [
                'Wind turbine technology',
                'Power systems',
                'Project management',
                'Technical documentation',
              ],
              cities: ['Hamburg', 'Bremen', 'Berlin'],
            },
          },
        },
      },
      'Power Systems': {
        roles: {
          'Power Systems Engineer': {
            Germany: {
              demandLevel: 'High',
              averageSalary: '€58,000 - €78,000',
              visaProcess: 'EU Blue Card eligible',
              keyRequirements: [
                'Grid integration',
                'Power distribution',
                'Energy storage systems',
              ],
              cities: ['Munich', 'Frankfurt', 'Berlin'],
            },
          },
        },
      },
    },
  },

  'Construction & Infra': {
    iconName: 'Building2',
    subIndustries: {
      'Civil Engineering': {
        roles: {
          'Structural Engineer': {
            Germany: {
              demandLevel: 'High',
              averageSalary: '€50,000 - €70,000',
              visaProcess: 'EU Blue Card eligible',
              keyRequirements: [
                'Structural analysis',
                'AutoCAD',
                'Building codes',
                'Project management',
              ],
              cities: ['Berlin', 'Munich', 'Frankfurt'],
            },
          },
        },
      },
    },
  },

  'Logistics & Supply Chain': {
    iconName: 'Truck',
    subIndustries: {
      'Supply Chain Management': {
        roles: {
          'Supply Chain Manager': {
            Netherlands: {
              demandLevel: 'High',
              averageSalary: '€55,000 - €75,000',
              visaProcess: 'Knowledge migrant visa',
              keyRequirements: [
                'Supply chain optimization',
                'ERP systems',
                'Inventory management',
                'Logistics planning',
              ],
              cities: ['Rotterdam', 'Amsterdam', 'Eindhoven'],
            },
          },
        },
      },
    },
  },
};

export default marketData;
