import { Globe, Rocket, Lightbulb, CheckCircle } from 'lucide-react';

const GeometricBackground = () => (
  <div className="absolute inset-0 overflow-hidden">
    {/* Large circles */}
    <div className="absolute -right-20 -top-20 h-96 w-96 rounded-full border-8 border-blue-200/30" />
    <div className="absolute -left-40 bottom-0 h-72 w-72 rounded-full border-4 border-purple-200/30" />

    {/* Small dots grid */}
    <div className="absolute left-0 right-0 top-0">
      <div className="grid grid-cols-12 gap-8 p-8">
        {[...Array(48)].map((_, i) => (
          <div key={i} className="h-2 w-2 rounded-full bg-gray-200/40" />
        ))}
      </div>
    </div>

    {/* Diagonal lines */}
    <div className="absolute bottom-0 right-0 h-96 w-96">
      <div className="relative h-full w-full rotate-12">
        {[...Array(5)].map((_, i) => (
          <div
            key={i}
            className="absolute h-px w-full bg-gradient-to-r from-transparent via-purple-200/40 to-transparent"
            style={{ top: `${i * 20}%` }}
          />
        ))}
      </div>
    </div>

    {/* Additional decorative elements */}
    <div className="absolute left-1/4 top-1/4 h-32 w-32 rotate-45 border-2 border-amber-200/30" />
    <div className="absolute bottom-1/4 right-1/3 h-24 w-24 rounded-lg border-2 border-blue-200/30" />
  </div>
);

const JourneyPaths = () => {
  const paths = [
    {
      icon: Globe,
      title: 'International Placement',
      description:
        'Direct placement opportunities with leading companies in Europe, Middle East, and beyond',
      metrics: ['30+ Countries', '48h Response', '95% Success Rate'],
      gradient: 'from-blue-500 to-blue-600',
    },
    {
      icon: Rocket,
      title: 'Skill Acceleration',
      description:
        'Intensive training programs to prepare you for global tech roles and certifications',
      metrics: ['12+ Programs', 'Industry Aligned', 'Mentor Support'],
      gradient: 'from-purple-500 to-purple-600',
    },
    {
      icon: Lightbulb,
      title: 'Innovation Track',
      description:
        'Specialized paths for emerging technologies and future-ready skill development',
      metrics: ['AI/ML Focus', 'Web3 Ready', 'Cloud Native'],
      gradient: 'from-amber-500 to-amber-600',
    },
  ];

  return (
    <div className="relative flex min-h-[600px] md:min-h-[700px] lg:min-h-[800px] xl:min-h-[900px] items-center bg-gray-50/75 px-4 py-12 lg:py-16">
      <GeometricBackground />

      <div className="relative mx-auto w-full max-w-6xl">
        <div className="mb-8 lg:mb-12 text-center">
          <h2 className="mb-3 lg:mb-4 text-3xl lg:text-4xl font-bold tracking-tight text-gray-900">
            Choose Your Growth Path
          </h2>
          <p className="mx-auto max-w-2xl text-base lg:text-lg text-gray-600">
            Multiple pathways to accelerate your global career journey, tailored
            to your goals and experience level
          </p>
        </div>

        <div className="grid gap-6 lg:gap-8 md:grid-cols-3">
          {paths.map((path, index) => {
            const Icon = path.icon;
            return (
              <div
                key={index}
                className={`group relative overflow-hidden rounded-xl lg:rounded-2xl bg-white p-8 lg:p-10 shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02]`}
              >
                {/* Decorative corner accent */}
                <div
                  className={`absolute -right-4 -top-4 h-16 w-16 bg-gradient-to-br ${path.gradient} opacity-10 blur-2xl transition-opacity duration-300 group-hover:opacity-20`}
                />

                <div className="relative">
                  <div
                    className={`mb-6 lg:mb-8 inline-flex h-12 lg:h-16 w-12 lg:w-16 items-center justify-center rounded-xl lg:rounded-2xl bg-gradient-to-br ${path.gradient} text-white`}
                  >
                    <Icon className="h-6 lg:h-8 w-6 lg:w-8" />
                  </div>

                  <h3 className="mb-3 lg:mb-4 text-xl lg:text-2xl font-semibold text-gray-900">
                    {path.title}
                  </h3>
                  <p className="mb-8 lg:mb-10 text-base lg:text-lg text-gray-600">
                    {path.description}
                  </p>

                  <div className="space-y-3 lg:space-y-4">
                    {path.metrics.map((metric, idx) => (
                      <div
                        key={idx}
                        className="flex items-center space-x-3 text-sm lg:text-base text-gray-700"
                      >
                        <CheckCircle className="h-5 lg:h-6 w-5 lg:w-6 text-green-500" />
                        <span>{metric}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default JourneyPaths;
