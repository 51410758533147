// import React from 'react';

// const ResultsView = ({ countries }) => {
//   return (
//     <div className="flex-1 bg-white rounded-xl border border-gray-200 flex flex-col overflow-hidden">
//       <div className="flex-1 overflow-y-auto h-full max-h-full p-6">
//         {countries.length > 0 ? (
//           <div className="space-y-6">
//             {countries.map(([country, data]) => (
//               <div
//                 key={country}
//                 className="bg-gray-50 rounded-xl p-6 border border-gray-200"
//               >
//                 <div className="flex justify-between items-start mb-4">
//                   <h3 className="text-xl font-semibold text-gray-900">
//                     {country}
//                   </h3>
//                   <span className="px-3 py-1 rounded-full text-sm font-medium bg-blue-50 text-blue-700">
//                     {data.demandLevel} Demand
//                   </span>
//                 </div>

//                 <div className="grid gap-4">
//                   <div>
//                     <h4 className="font-medium text-gray-900">Salary Range</h4>
//                     <p className="text-gray-600 mt-1">{data.averageSalary}</p>
//                   </div>
//                   <div>
//                     <h4 className="font-medium text-gray-900">Visa Process</h4>
//                     <p className="text-gray-600 mt-1">{data.visaProcess}</p>
//                   </div>
//                   <div>
//                     <h4 className="font-medium text-gray-900">
//                       Key Requirements
//                     </h4>
//                     <div className="flex flex-wrap gap-2 mt-1">
//                       {data.keyRequirements.map((req) => (
//                         <span
//                           key={req}
//                           className="px-2 py-1 rounded text-sm bg-blue-50 text-blue-700"
//                         >
//                           {req}
//                         </span>
//                       ))}
//                     </div>
//                   </div>
//                   <div>
//                     <h4 className="font-medium text-gray-900">Major Cities</h4>
//                     <div className="flex flex-wrap gap-2 mt-1">
//                       {data.cities.map((city) => (
//                         <span
//                           key={city}
//                           className="px-2 py-1 bg-white rounded text-sm text-gray-600 border border-gray-200"
//                         >
//                           {city}
//                         </span>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         ) : (
//           <div className="h-full flex items-center justify-center text-gray-500">
//             Select an industry and role to view opportunities
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ResultsView;

// // ResultsView.js
// import React from 'react';
// import useIsMobile from '../../../hooks/useIsMobile';

// const ResultsView = ({ countries }) => {
//   const isMobile = useIsMobile();

//   return (
//     <div
//       className={`
//       ${isMobile ? 'bg-white' : 'flex-1 bg-white rounded-xl border border-gray-200'}
//       flex flex-col overflow-hidden
//     `}
//     >
//       <div
//         className={`
//         flex-1 overflow-y-auto
//         ${isMobile ? 'p-4' : 'h-[calc(100vh-200px)] p-6'}
//       `}
//       >
//         {countries.length > 0 ? (
//           <div className="space-y-6">
//             {countries.map(([country, data]) => (
//               <div
//                 key={country}
//                 className="bg-gray-50 rounded-xl p-6 border border-gray-200"
//               >
//                 <div className="flex justify-between items-start mb-4">
//                   <h3 className="text-xl font-semibold text-gray-900">
//                     {country}
//                   </h3>
//                   <span className="px-3 py-1 rounded-full text-sm font-medium bg-blue-50 text-blue-700">
//                     {data.demandLevel} Demand
//                   </span>
//                 </div>

//                 <div className="grid gap-4">
//                   <div>
//                     <h4 className="font-medium text-gray-900">Salary Range</h4>
//                     <p className="text-gray-600 mt-1">{data.averageSalary}</p>
//                   </div>
//                   <div>
//                     <h4 className="font-medium text-gray-900">Visa Process</h4>
//                     <p className="text-gray-600 mt-1">{data.visaProcess}</p>
//                   </div>
//                   <div>
//                     <h4 className="font-medium text-gray-900">
//                       Key Requirements
//                     </h4>
//                     <div className="flex flex-wrap gap-2 mt-1">
//                       {data.keyRequirements.map((req) => (
//                         <span
//                           key={req}
//                           className="px-2 py-1 rounded text-sm bg-blue-50 text-blue-700"
//                         >
//                           {req}
//                         </span>
//                       ))}
//                     </div>
//                   </div>
//                   <div>
//                     <h4 className="font-medium text-gray-900">Major Cities</h4>
//                     <div className="flex flex-wrap gap-2 mt-1">
//                       {data.cities.map((city) => (
//                         <span
//                           key={city}
//                           className="px-2 py-1 bg-white rounded text-sm text-gray-600 border border-gray-200"
//                         >
//                           {city}
//                         </span>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         ) : (
//           <div className="h-full flex items-center justify-center text-gray-500">
//             Select an industry and role to view opportunities
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ResultsView;

// ResultsView.js
// import React from 'react';
// import useIsMobile from '../../../hooks/useIsMobile';

// const ResultsView = ({ countries }) => {
//   const isMobile = useIsMobile();

//   return (
//     <div
//       className={`
//       animate-slideUp
//       ${isMobile ? 'bg-white rounded-lg shadow-sm' : 'flex-1 bg-white rounded-xl border border-gray-200'}
//       flex flex-col overflow-hidden
//     `}
//     >
//       <div
//         className={`
//         flex-1 overflow-y-auto
//         ${isMobile ? 'max-h-[70vh]' : 'h-full'}
//         p-4 md:p-6
//       `}
//       >
//         {countries.length > 0 ? (
//           <div className="space-y-6">
//             {countries.map(([country, data]) => (
//               <div
//                 key={country}
//                 className="bg-gray-50 rounded-xl p-6 border border-gray-200 hover:border-blue-200 transition-colors"
//               >
//                 <div className="flex flex-col sm:flex-row sm:items-start sm:justify-between gap-4 mb-6">
//                   <h3 className="text-xl font-semibold text-gray-900">
//                     {country}
//                   </h3>
//                   <span className="inline-flex px-3 py-1 rounded-full text-sm font-medium bg-blue-50 text-blue-700">
//                     {data.demandLevel} Demand
//                   </span>
//                 </div>

//                 <div className="grid gap-6 sm:grid-cols-2">
//                   <div>
//                     <h4 className="font-medium text-gray-900 mb-2">
//                       Salary Range
//                     </h4>
//                     <p className="text-gray-600">{data.averageSalary}</p>
//                   </div>
//                   <div>
//                     <h4 className="font-medium text-gray-900 mb-2">
//                       Visa Process
//                     </h4>
//                     <p className="text-gray-600">{data.visaProcess}</p>
//                   </div>
//                   <div className="sm:col-span-2">
//                     <h4 className="font-medium text-gray-900 mb-2">
//                       Key Requirements
//                     </h4>
//                     <div className="flex flex-wrap gap-2">
//                       {data.keyRequirements.map((req) => (
//                         <span
//                           key={req}
//                           className="px-3 py-1 rounded-full text-sm bg-blue-50 text-blue-700"
//                         >
//                           {req}
//                         </span>
//                       ))}
//                     </div>
//                   </div>
//                   <div className="sm:col-span-2">
//                     <h4 className="font-medium text-gray-900 mb-2">
//                       Major Cities
//                     </h4>
//                     <div className="flex flex-wrap gap-2">
//                       {data.cities.map((city) => (
//                         <span
//                           key={city}
//                           className="px-3 py-1 rounded-full text-sm bg-white border border-gray-200 text-gray-600"
//                         >
//                           {city}
//                         </span>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         ) : (
//           <div className="h-full flex items-center justify-center text-gray-500">
//             Complete your selection to view opportunities
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ResultsView;

// ResultsView.js
// import React from 'react';
// import useIsMobile from '../../../hooks/useIsMobile';

// const ResultsView = ({ countries }) => {
//   const isMobile = useIsMobile();

//   return (
//     <div
//       className={`
//       animate-slideUp
//       ${isMobile ? 'bg-white rounded-lg shadow-sm' : 'flex-1 bg-white rounded-xl border border-gray-200'}
//       flex flex-col overflow-hidden
//     `}
//     >
//       <div
//         className={`
//         flex-1 overflow-y-auto
//         ${isMobile ? 'max-h-[70vh]' : 'h-full'}
//         p-4 md:p-6
//       `}
//       >
//         {countries.length > 0 ? (
//           <div className="space-y-6">
//             {countries.map(([country, data]) => (
//               <div
//                 key={country}
//                 className="bg-gray-50 rounded-xl p-6 border border-gray-200 hover:border-blue-200 transition-colors"
//               >
//                 <div className="flex flex-col sm:flex-row sm:items-start sm:justify-between gap-4 mb-6">
//                   <h3 className="text-xl font-semibold text-gray-900">
//                     {country}
//                   </h3>
//                   <span className="inline-flex px-3 py-1 rounded-full text-sm font-medium bg-blue-50 text-blue-700">
//                     {data.demandLevel} Demand
//                   </span>
//                 </div>

//                 <div className="grid gap-6 sm:grid-cols-2">
//                   <div>
//                     <h4 className="font-medium text-gray-900 mb-2">
//                       Salary Range
//                     </h4>
//                     <p className="text-gray-600">{data.averageSalary}</p>
//                   </div>
//                   <div>
//                     <h4 className="font-medium text-gray-900 mb-2">
//                       Visa Process
//                     </h4>
//                     <p className="text-gray-600">{data.visaProcess}</p>
//                   </div>
//                   <div className="sm:col-span-2">
//                     <h4 className="font-medium text-gray-900 mb-2">
//                       Key Requirements
//                     </h4>
//                     <div className="flex flex-wrap gap-2">
//                       {data.keyRequirements.map((req) => (
//                         <span
//                           key={req}
//                           className="px-3 py-1 rounded-full text-sm bg-blue-50 text-blue-700"
//                         >
//                           {req}
//                         </span>
//                       ))}
//                     </div>
//                   </div>
//                   <div className="sm:col-span-2">
//                     <h4 className="font-medium text-gray-900 mb-2">
//                       Major Cities
//                     </h4>
//                     <div className="flex flex-wrap gap-2">
//                       {data.cities.map((city) => (
//                         <span
//                           key={city}
//                           className="px-3 py-1 rounded-full text-sm bg-white border border-gray-200 text-gray-600"
//                         >
//                           {city}
//                         </span>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         ) : (
//           <div className="h-full flex items-center justify-center text-gray-500">
//             Complete your selection to view opportunities
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ResultsView;

// // ResultsView.js
// import React from 'react';
// import useIsMobile from '../../../hooks/useIsMobile';

// const ResultsView = ({ countries }) => {
//   const isMobile = useIsMobile();

//   return (
//     <div className={`
//       ${isMobile ? 'bg-white' : 'flex-1 bg-white rounded-xl border border-gray-200'}
//       ${isMobile ? 'animate-slideUp' : 'h-[600px]'} // Matching height with selection panel
//       overflow-hidden
//     `}>
//       <div className="h-full overflow-y-auto p-4 md:p-6">
//         {countries.length > 0 ? (
//           <div className="space-y-6">
//             {countries.map(([country, data]) => (
//               <div
//                 key={country}
//                 className="bg-gray-50 rounded-xl p-6 border border-gray-200"
//               >
// <div className="flex flex-col sm:flex-row sm:items-start sm:justify-between gap-4 mb-6">
//                   <h3 className="text-xl font-semibold text-gray-900">
//                     {country}
//                   </h3>
//                   <span className="inline-flex px-3 py-1 rounded-full text-sm font-medium bg-blue-50 text-blue-700">
//                     {data.demandLevel} Demand
//                   </span>
//                 </div>

//                 <div className="grid gap-6 sm:grid-cols-2">
//                   <div>
//                     <h4 className="font-medium text-gray-900 mb-2">
//                       Salary Range
//                     </h4>
//                     <p className="text-gray-600">{data.averageSalary}</p>
//                   </div>
//                   <div>
//                     <h4 className="font-medium text-gray-900 mb-2">
//                       Visa Process
//                     </h4>
//                     <p className="text-gray-600">{data.visaProcess}</p>
//                   </div>
//                   <div className="sm:col-span-2">
//                     <h4 className="font-medium text-gray-900 mb-2">
//                       Key Requirements
//                     </h4>
//                     <div className="flex flex-wrap gap-2">
//                       {data.keyRequirements.map((req) => (
//                         <span
//                           key={req}
//                           className="px-3 py-1 rounded-full text-sm bg-blue-50 text-blue-700"
//                         >
//                           {req}
//                         </span>
//                       ))}
//                     </div>
//                   </div>
//                   <div className="sm:col-span-2">
//                     <h4 className="font-medium text-gray-900 mb-2">
//                       Major Cities
//                     </h4>
//                     <div className="flex flex-wrap gap-2">
//                       {data.cities.map((city) => (
//                         <span
//                           key={city}
//                           className="px-3 py-1 rounded-full text-sm bg-white border border-gray-200 text-gray-600"
//                         >
//                           {city}
//                         </span>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         ) : (
//           <div className="h-full flex items-center justify-center text-gray-500">
//             Select an industry and role to view opportunities
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ResultsView;

// ResultsView.js
// import React from 'react';
// import useIsMobile from '../../../hooks/useIsMobile';

// const ResultsView = ({ countries }) => {
//   const isMobile = useIsMobile();

//   return (
//     <div
//       className={`
//       ${
//         isMobile
//           ? 'bg-white animate-slideUp h-[400px]'
//           : 'flex-1 bg-white rounded-xl border border-gray-200 h-[600px]'
//       }
//       overflow-hidden
//     `}
//     >
//       <div className="h-full overflow-y-auto p-4 md:p-6">
//         {countries.length > 0 ? (
//           <div className="space-y-6">
//             {countries.map(([country, data]) => (
//               <div
//                 key={country}
//                 className="bg-gray-50 rounded-xl p-6 border border-gray-200 hover:border-blue-200 transition-colors"
//               >
//                 <div className="flex flex-col sm:flex-row sm:items-start sm:justify-between gap-4 mb-6">
//                   <h3 className="text-xl font-semibold text-gray-900">
//                     {country}
//                   </h3>
//                   <span className="inline-flex px-3 py-1 rounded-full text-sm font-medium bg-blue-50 text-blue-700">
//                     {data.demandLevel} Demand
//                   </span>
//                 </div>

//                 <div className="grid gap-6 sm:grid-cols-2">
//                   <div>
//                     <h4 className="font-medium text-gray-900 mb-2">
//                       Salary Range
//                     </h4>
//                     <p className="text-gray-600">{data.averageSalary}</p>
//                   </div>

//                   <div>
//                     <h4 className="font-medium text-gray-900 mb-2">
//                       Visa Process
//                     </h4>
//                     <p className="text-gray-600">{data.visaProcess}</p>
//                   </div>

//                   <div className="sm:col-span-2">
//                     <h4 className="font-medium text-gray-900 mb-2">
//                       Key Requirements
//                     </h4>
//                     <div className="flex flex-wrap gap-2">
//                       {data.keyRequirements.map((req) => (
//                         <span
//                           key={req}
//                           className="px-3 py-1 rounded-full text-sm bg-blue-50 text-blue-700"
//                         >
//                           {req}
//                         </span>
//                       ))}
//                     </div>
//                   </div>

//                   <div className="sm:col-span-2">
//                     <h4 className="font-medium text-gray-900 mb-2">
//                       Major Cities
//                     </h4>
//                     <div className="flex flex-wrap gap-2">
//                       {data.cities.map((city) => (
//                         <span
//                           key={city}
//                           className="px-3 py-1 rounded-full text-sm bg-white border border-gray-200 text-gray-600"
//                         >
//                           {city}
//                         </span>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         ) : (
//           <div className="h-full flex items-center justify-center text-gray-500">
//             Select an industry and role to view opportunities
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ResultsView;

import React from 'react';
import useIsMobile from '../../../hooks/useIsMobile';

const ResultsView = ({ countries }) => {
  const isMobile = useIsMobile();

  return (
    <div
      className={`
      ${
        isMobile
          ? 'bg-white animate-slideUp h-[400px]'
          : 'flex-1 bg-white rounded-xl border border-gray-200 h-[500px]'
      }
      overflow-hidden
    `}
    >
      <div className="h-full overflow-y-auto p-4 md:p-6">
        {countries.length > 0 ? (
          <div className="space-y-6">
            {countries.map(([country, data]) => (
              <div
                key={country}
                className="bg-gray-50 rounded-xl p-6 border border-gray-200 hover:border-blue-200 transition-colors"
              >
                <div className="flex flex-col sm:flex-row sm:items-start sm:justify-between gap-4 mb-6">
                  <h3 className="text-xl font-semibold text-gray-900">
                    {country}
                  </h3>
                  <span className="inline-flex px-3 py-1 rounded-full text-sm font-medium bg-blue-50 text-blue-700">
                    {data.demandLevel} Demand
                  </span>
                </div>

                <div className="grid gap-6 sm:grid-cols-2">
                  <div>
                    <h4 className="font-medium text-gray-900 mb-2">
                      Salary Range
                    </h4>
                    <p className="text-gray-600">{data.averageSalary}</p>
                  </div>

                  <div>
                    <h4 className="font-medium text-gray-900 mb-2">
                      Visa Process
                    </h4>
                    <p className="text-gray-600">{data.visaProcess}</p>
                  </div>

                  <div className="sm:col-span-2">
                    <h4 className="font-medium text-gray-900 mb-2">
                      Key Requirements
                    </h4>
                    <div className="flex flex-wrap gap-2">
                      {data.keyRequirements.map((req) => (
                        <span
                          key={req}
                          className="px-3 py-1 rounded-full text-sm bg-blue-50 text-blue-700"
                        >
                          {req}
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <h4 className="font-medium text-gray-900 mb-2">
                      Major Cities
                    </h4>
                    <div className="flex flex-wrap gap-2">
                      {data.cities.map((city) => (
                        <span
                          key={city}
                          className="px-3 py-1 rounded-full text-sm bg-white border border-gray-200 text-gray-600"
                        >
                          {city}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="h-full flex items-center md:text-lg justify-center text-gray-500">
            Select an industry, Sector and role to view opportunities in
            different countries in Europe.
          </div>
        )}
      </div>
    </div>
  );
};

export default ResultsView;
